
import NavHeader from "../nav/NavHeader.vue";
import SeButton from "../components/global/SeButton.vue";
import SeInput from "../components/global/SeInput.vue";
import * as DateUtils from "@/utils/DateUtils";
import dateFormat from "dateformat";
import SeFadeButton from "../components/global/SeFadeButton.vue";
import { getElementManager } from "@/firebase/ElementManager";
import * as TaskManager from "@/firebase/TaskManager";
import { defineComponent } from "@vue/runtime-core";
import Element from "@/model/Elements/Element";
import { getState } from "@/pinia/NavigationState";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import Database from "@/model/Database";
import RRuleInput from "@/components/databases/task_configurations/RRuleInput.vue";
import ElementSelectList from "@/components/databases/task_configurations/ElementSelectList.vue";
import { datetime, RRule, RRuleSet, rrulestr } from "rrule";
import * as Lang from "@/i18n/lang";
import TaskActionList from "@/components/databases/TaskActionList.vue";
import { doc, collection, DocumentReference } from "firebase/firestore";
import LockedField from "@/components/global/LockedField.vue";
import { ModuleEnum } from "@/model/ModuleEnum";
import * as ModuleManager from "@/firebase/ModuleManager";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Storage from "@/firebase/Storage";
import * as Modals from "@/utils/Modals";
import * as Snackbars from "@/utils/Snackbars";
import TaskAction from "@/model/Tasks/TaskAction";
import TaskType from "@/model/Tasks/TaskType";
import * as TaskTypeManager from "@/firebase/TaskTypeManager";
import { nextTick } from "vue";
import { getDatabaseTaskConfigurationState } from "@/pinia/tasks/DatabaseTaskConfigurationState";
import StoreTaskConfigurationView from "./StoreTaskConfigurationView.vue";
import { compareObjects } from "@/utils/ObjectUtils";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import Attachment from "@/model/Filesystem/Attachment";
import { generateUniqueId } from "@/model/utils/uniqueIdGenerator";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import { AttachmentType } from "@/model/Filesystem/AttachmentType";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as Firebase from "@/firebase/Firebase";
import TaskModuleConfigurationAction from "@/model/Tasks/config/TaskModuleConfigurationAction";
import TaskModuleConfigurationTemperature from "@/model/Tasks/config/TaskModuleConfigurationTemperature";
import TaskModuleConfigurationOils from "@/model/Tasks/config/TaskModuleConfigurationOils";
import FirebaseUploadStrategy from "@/model/Uploads/FirebaseUploadStrategy";

export default defineComponent({
  components: {
    RRuleInput,
    ElementSelectList,
    TaskActionList,
    LockedField,
  },
  data() {
    let rruleText = Lang.getI18N("modify");

    return {
      actionAttachments: [] as Attachment[],
      rruleText: rruleText,
      schedules: [] as any[],
      scheduleStart: new Date(0, 0, 0, 0, 0, 0, 0),
      scheduleEnd: new Date(0, 0, 0, 0, 0, 0, 0),
      ModuleEnum: ModuleEnum,
      dateFormat: dateFormat,
      DateUtils: DateUtils,
      selectedElements: [] as Element[],
      validElements: [] as Element[],
      taskActions: [] as TaskAction[],
      Lang: Lang,
      elementManager: getElementManager(),
      manager: getTaskConfigurationManager(),
    };
  },
  async setup() {
    let state = getDatabaseTaskConfigurationState();

    await state.setup();

    return {
      state: state,
    };
  },
  async mounted() {
    let refs = <any>this.$refs;

    let ruleStr = rrulestr(this.state.taskConfiguration!.getRecurrence()!);

    this.rruleText = DateUtils.getRRuleFrequenceText(ruleStr);

    let rrule = rrulestr(this.state.taskConfiguration!.getRecurrence()!);
    refs.rruleInput.fromRRule(rrule);

    if (!this.state.creation) {
      for (
        let i = 0;
        i < this.state.taskConfiguration!.getStarts()!.length;
        i++
      ) {
        let start = this.state.taskConfiguration!.getStarts()![i];
        let duration = this.state.taskConfiguration!.getDurations()![i];

        this.schedules.push({
          start: start,
          end: start + duration,
        });
      }

      this.schedules.sort((a, b) => a.start - b.start);

      this.state.selectedModule = ModuleManager.getModuleFromRef(
        this.state.taskConfiguration?.getModule()!
      )?.toEnum()!;

      let configuration = <any>this.state.taskConfiguration!.getConfiguration();

      if (this.state.selectedModule == ModuleEnum.Temperatures) {
        for (let i = 0; i < configuration.temperature_elements.length; i++) {
          let element = await this.elementManager.getDatabaseElementByReference(
            this.state.getDatabaseId(),
            configuration.temperature_elements[i]
          );
          this.selectedElements.push(element!);
        }
      } else if (this.state.selectedModule == ModuleEnum.Oils) {
        for (let i = 0; i < configuration.oil_elements.length; i++) {
          let element = await this.elementManager.getDatabaseElementByReference(
            this.state.getDatabaseId(),
            configuration.oil_elements[i]
          );
          this.selectedElements.push(element!);
        }
      } else if (this.state.selectedModule == ModuleEnum.Traceability) {
        for (let i = 0; i < configuration.traceability.length; i++) {
          let element = await this.elementManager.getDatabaseElementByReference(
            this.state.getDatabaseId(),
            configuration.traceability[i]
          );
          this.selectedElements.push(element!);
        }
      } else if (this.state.selectedModule == ModuleEnum.Labels) {
        for (let i = 0; i < configuration.labels.length; i++) {
          let element = await this.elementManager.getDatabaseElementByReference(
            this.state.getDatabaseId(),
            configuration.labels[i]
          );
          this.selectedElements.push(element!);
        }
      } else if (this.state.selectedModule == ModuleEnum.Action) {
        this.taskActions = configuration.actions.map((x: any) =>
          TaskAction.fromFirestore(x)
        );
        if (this.taskActions == null || this.taskActions == undefined) {
          this.taskActions = [];
        }
      }
    }

    if (this.state.selectedModule != null) {
      this.validElements = await this.elementManager.getElementsByModule(
        this.state.selectedModule!,
        this.state.getDatabaseId()
      );
    }

    this.state.taskTypes = await TaskTypeManager.getDatabaseTaskTypes(
      this.state.getDatabaseId()
    );

    if (this.state.taskConfiguration!.type != null) {
      var type = this.state.taskTypes.find(
        (x) => x.ref!.id == this.state.taskConfiguration?.type?.id!
      );

      if (type != undefined) {
        this.state.selectedTaskType = type!;
      }
    }
  },
  methods: {
    moveDownAttachement(attachment: Attachment) {
      var refs = <any>this.$refs;

      var modal = refs.modifyActionModal;

      var action = <TaskAction>modal.context;

      var index = action.attachments?.indexOf(attachment)!;

      if (index <= 0 || index >= action.attachments!.length) {
        return;
      }

      // Swap the element at the given index with the previous one
      let temp = action.attachments![index];
      action.attachments![index] = action.attachments![index - 1];
      action.attachments![index - 1] = temp;
    },
    moveUpAttachement(attachment: Attachment) {
      var refs = <any>this.$refs;

      var modal = refs.modifyActionModal;

      var action = <TaskAction>modal.context;

      var index = action.attachments?.indexOf(attachment)!;

      // Check if the index is within the bounds of the array
      if (index < 0 || index >= action.attachments!.length - 1) {
        return;
      }

      // Swap the element at the given index with the next one
      let temp = action.attachments![index];
      action.attachments![index] = action.attachments![index + 1];
      action.attachments![index + 1] = temp;
    },
    async downloadAttachement(attachment: Attachment) {
      await attachment.download();
    },
    uploadAttachment() {
      DOMUtils.attachementFileDialog(this.onAttachmentUploaded);
    },
    async onAttachmentUploaded(e: any) {
      let file = e.target.files[0];

      var refs = <any>this.$refs;

      var modal = refs.modifyActionModal;

      var action = <TaskAction>modal.context;

      var path =
        "/databases/" +
        this.state.getDatabaseId() +
        "/task_configurations_attachments/" +
        this.state.taskConfiguration?.ref?.id!;

      var strategy = await UploadManager.createStrategy(file, path, true);

      action.attachments.push(strategy.generateAttachment()!);

      await UploadManager.addFileToUpload(strategy);
    },

    async deleteAttachement(attachment: Attachment) {
      var refs = <any>this.$refs;

      var modal = refs.modifyActionModal;

      var action = <TaskAction>modal.context;

      var index = action.attachments.indexOf(attachment);

      action.attachments.splice(index, 1);

      UploadManager.addFileToDelete(attachment);
    },
    openEditScheduleModal(schedule: any) {
      this.scheduleStart = DateUtils.getDateFromMinutes(schedule.start - 60);
      this.scheduleEnd = DateUtils.getDateFromMinutes(schedule.end - 60);

      let refs = <any>this.$refs;
      refs.scheduleModal.open(schedule);
    },
    openEditTaskTypeModal(taskType: TaskType) {
      var refs = <any>this.$refs;

      refs.taskTypeName.setValue(taskType.name);
      refs.colorPicker.setValue(taskType.color);
      refs.taskTypeModal.open(taskType);
    },

    async duplicate() {
      this.apply();

      let refs = <any>this.$refs;

      if (refs.duplicateBtn.isLoading()) {
        return;
      }
      refs.duplicateBtn.toggleLoading(true);

      let cloned = DatabaseTaskConfiguration.duplicate(
        this.state.taskConfiguration!
      );

      cloned.name += " (Copy)";

      await this.manager.createDatabaseTaskConfiguration(
        this.state.getDatabaseId(),
        cloned
      );

      let state = getState();
      state.goBack();
    },
    async modifyOrCreateTaskType() {
      var refs = <any>this.$refs;

      var name = refs.taskTypeName.getValue();

      var color = refs.colorPicker.getValue();

      if (name.trim() == "") {
        return;
      }

      if (refs.taskTypeModal.context == null) {
        var type = new TaskType(
          doc(
            collection(
              Firebase.firestore,
              this.state.database!.ref.path,
              "task_types"
            )
          ),
          name,
          color,
          false
        );

        await TaskTypeManager.createDatabaseTaskType(
          this.state.getDatabaseId(),
          type
        );

        this.state.taskTypes.push(type);
        this.state.selectedTaskType = type;
      } else {
        var taskType = <TaskType>refs.taskTypeModal.context;

        taskType.name = name;
        taskType.color = color;
        await TaskTypeManager.modifyTaskType(taskType);
      }

      refs.taskTypeModal.close();
    },
    openTaskTypeModal() {
      var refs = <any>this.$refs;
      refs.taskTypeName.setValue("");
      refs.colorPicker.setValue("#454eff");
      refs.taskTypeModal.open();
    },
    modifyAction() {
      let refs = <any>this.$refs;
      let action = refs.modifyActionModal.context;

      action.action = refs.actionNameMod.getValue();

      refs.modifyActionModal.close();

      this.applyConfiguration();
    },
    openAddScheduleModal() {
      let refs = <any>this.$refs;
      refs.scheduleModal.open();
    },
    openAddElementModal() {
      let refs = <any>this.$refs;
      refs.addElementModal.open();
    },
    openRRuleModal() {
      let refs = <any>this.$refs;
      refs.rruleModal.open();

      let rrule = rrulestr(this.state.taskConfiguration!.getRecurrence()!);
      refs.rruleInput.fromRRule(rrule);
    },
    openAddActionModal(): any {
      let refs = <any>this.$refs;
      refs.actionName.setValue("");
      refs.addActionModal.open();
    },
    openModifyActionModal(action: TaskAction) {
      let refs = <any>this.$refs;
      refs.actionNameMod.setValue(action.action);
      refs.modifyActionModal.open(action);

      this.actionAttachments = action.attachments;
    },
    removeAction(action: TaskAction) {
      const index = this.taskActions.indexOf(action);
      if (index > -1) {
        this.taskActions.splice(index, 1);
      }
      this.applyConfiguration();
    },
    applyConfiguration() {
      let configuration = this.computeConfiguration();
      this.state.taskConfiguration!.setConfiguration(configuration);
    },
    applySchedules() {
      this.state.taskConfiguration!.setStarts([]);
      this.state.taskConfiguration!.setDurations([]);

      this.schedules.forEach((schedule: any) => {
        this.state.taskConfiguration!.getStarts()!.push(schedule.start);
        this.state
          .taskConfiguration!.getDurations()!
          .push(schedule.end - schedule.start);
      });

      this.schedules.sort((a, b) => a.start - b.start);
    },
    addAction() {
      let refs = <any>this.$refs;
      let requirePicture = false;
      let actionName = refs.actionName.getValue();

      if (actionName == "") {
        return;
      }
      refs.addActionModal.close();

      this.taskActions.push(new TaskAction(actionName, requirePicture));

      this.applyConfiguration();
    },

    async onModuleChange(module: ModuleEnum) {
      if (module == ModuleEnum.Temperatures || module == ModuleEnum.Oils) {
        this.validElements = await this.elementManager.getElementsByModule(
          module,
          this.state.getDatabaseId()
        );
        this.selectedElements = [];
      }
    },
    addElements() {
      var refs = <any>this.$refs;

      this.selectedElements = refs.elementSelect.getSelectedElements();

      refs.addElementModal.close();

      this.$forceUpdate();
    },

    computeConfiguration(): any {
      let configuration: any = {};

      let elementRefs: DocumentReference[] = [];
      this.selectedElements.forEach((element) => {
        elementRefs.push(element.ref!);
      });

      if (this.state.selectedModule! == ModuleEnum.Temperatures) {
        configuration = new TaskModuleConfigurationTemperature(
          elementRefs,
          false,
          false
        );
      } else if (this.state.selectedModule! == ModuleEnum.Oils) {
        configuration = new TaskModuleConfigurationOils(elementRefs);
      } else if (this.state.selectedModule! == ModuleEnum.Action) {
        configuration = new TaskModuleConfigurationAction(this.taskActions);
      }

      return configuration;
    },

    closeRRuleModal() {
      let refs = <any>this.$refs;
      refs.rruleModal.close();
      let rule = refs.rruleInput.computeRRule();
      this.state.taskConfiguration!.recurrence = rule.toString();
      this.rruleText = DateUtils.getRRuleFrequenceText(
        refs.rruleInput.computeRRule()
      );
    },
    async apply() {
      this.state.changeSaved = true;

      let refs = <any>this.$refs;

      if (refs.applyBtn.isLoading()) {
        return;
      }

      if (!refs.submitForm.verify()) {
        return;
      }

      if (this.state.selectedModule == null) {
        Modals.open(Lang.getI18N("task-configuration.select-module"));
        return;
      }
      if (
        this.state.selectedModule == ModuleEnum.Action &&
        this.taskActions.length == 0
      ) {
        refs.modal.open();
        return;
      }

      refs.applyBtn.toggleLoading(true);

      try {
        var module = ModuleManager.getModuleFromEnum(
          this.state.selectedModule!
        );
        this.state.taskConfiguration!.setModule(module.ref!);

        if (this.state.selectedTaskType != null) {
          this.state.taskConfiguration!.setType(
            this.state.selectedTaskType?.ref!
          );
        } else {
          this.state.taskConfiguration?.setType(null);
        }

        this.applySchedules();

        this.applyConfiguration();

        await UploadManager.applyUploads();

        if (this.state.creation) {
          this.state.creation = false;
        }

        if (
          ModuleManager.getModuleFromRef(
            this.state.taskConfiguration?.module!
          )?.toEnum() == ModuleEnum.Action
        ) {
          var config = <TaskModuleConfigurationAction>(
            this.state.taskConfiguration?.configuration!
          );

          for (let action of config.actions) {
            for (let attachment of action.attachments) {
              await attachment.generateUrl();
            }
          }
        }

        this.state.taskConfiguration?.set();

        await UploadManager.applyDeletions();

        Snackbars.display(Lang.getI18N("saved-successfully"));
        refs.applyBtn.toggleLoading(false);
      } catch (e: any) {
        Snackbars.display(Lang.getI18N("save-error"), 3000, "error");
        refs.applyBtn.toggleLoading(false);
      }
    },
    removeSchedule(schedule: any) {
      const index = this.schedules.indexOf(schedule);
      if (index > -1) {
        this.schedules.splice(index, 1);
      }
      this.applySchedules();
    },

    addOrEditSchedule() {
      if (this.scheduleStart > this.scheduleEnd) {
        return;
      }
      let refs = <any>this.$refs;

      let start =
        this.scheduleStart.getHours() * 60 + this.scheduleStart.getMinutes();

      let end =
        this.scheduleEnd.getHours() * 60 + this.scheduleEnd.getMinutes();

      if (refs.scheduleModal.context != null) {
        let schedule: any = refs.scheduleModal.context;
        schedule.start = start;
        schedule.end = end;
      } else {
        this.schedules.push({
          start: start,
          end: end,
        });
      }

      this.applySchedules();

      refs.scheduleModal.close();
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.state.changeSaved) {
      next();
      return;
    }
    if (
      this.state.creation ||
      !compareObjects(
        this.state.initialTaskConfiguration,
        this.state.taskConfiguration
      )
    ) {
      const confirmLeave = await Modals.openYesNo(
        Lang.getI18N("unsaved-changes"),
        "Secureat",
        Lang.getI18N("yes"),
        Lang.getI18N("no")
      );
      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
});
