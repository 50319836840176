import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6743effa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _renderSlot(_ctx.$slots, "columns", {}, undefined, true)
        ])
      ]),
      _createElementVNode("tbody", null, [
        _renderSlot(_ctx.$slots, "rows", {}, undefined, true)
      ])
    ])
  ]))
}