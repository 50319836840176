import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Database from "../Database";
import { toImageKit } from "@/utils/ImageUtils";
import SecureatIllustration from "../utils/SecureatIllustration";
import PunchCardEntry from "./PunchCardEntry";

export default class EmployeePunchCard extends SecureatSnapshot {
  sort() {
    for (let key of this.data.keys()) {
      this.data.set(
        key,
        new Map(
          [...this.data.get(key).entries()].sort((a, b) => {
            return a[0].localeCompare(b[0]);
          })
        )
      );
    }
  }
  data: Map<string, Map<string, PunchCardEntry[]>> = new Map<
    string,
    Map<string, PunchCardEntry[]>
  >();

  constructor(
    ref: DocumentReference,
    data: Map<string, Map<string, PunchCardEntry[]>>
  ) {
    super(ref);
    this.data = data;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): EmployeePunchCard {
    let result = new EmployeePunchCard(snapshot.ref, new Map());

    const docData = snapshot.data()!;

    if (!docData) {
      return result;
    }

    var keys = Object.keys(docData.data);

    for (let storeId of keys) {
      result.data.set(storeId, new Map());

      let storeData = docData.data[storeId];

      for (let date of Object.keys(storeData)) {
        let entries = storeData[date].map((x: any) =>
          PunchCardEntry.fromFirestore(x)
        );
        result.data.get(storeId)!.set(date, entries);
      }
    }

    return result;
  }

  public toFirestore() {
    const docData: { [storeId: string]: { [date: string]: any[] } } = {};

    for (const [storeId, dateMap] of this.data.entries()) {
      docData[storeId] = {};

      for (const [date, entries] of dateMap.entries()) {
        docData[storeId][date] = entries.map((entry) => entry.toFirestore());
      }
    }

    return { data: docData };
  }
}
