
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  props: ["status"],
  methods: {
    getColor(): string {
      switch (this.status) {
        case "postponed":
          return "#f3841c";
        case "done":
          return "#1dd000";
        case "late":
          return "#eb3030";
        case "on-time":
          return "#454eff";
      }

      return "unknown";
    },
    getStatusText(): string {
      switch (this.status) {
        case "postponed":
          return Lang.getI18N("postponed");
        case "done":
          return Lang.getI18N("done");
        case "late":
          return Lang.getI18N("late");
        case "on-time":
          return Lang.getI18N("on-time");
      }

      return "unknown";
    },
  },
});
