
import { defineComponent, nextTick } from "vue";
import * as Lang from "@/i18n/lang";

import SeSimpleTab from "@/components/global/SeSimpleTab.vue";
import { ModuleEnum } from "@/model/ModuleEnum";
import Task from "@/model/Task";
import * as TaskManager from "@/firebase/TaskManager";
import TemperatureResultTable from "@/components/tasks/table/TemperatureResultTable.vue";
import Store from "@/model/Store";
import SeLoadingIndicator from "@/components/global/SeLoadingIndicator.vue";
import TraceabilityResultTable from "@/components/tasks/table/TraceabilityResultTable.vue";
import ActionResultTable from "@/components/tasks/table/ActionResultTable.vue";
import OilResultTable from "@/components/tasks/table/OilResultTable.vue";
import TemperatureControlTable from "@/components/tasks/table/TemperatureControlTable.vue";
import { getStoreManager } from "@/firebase/StoreManager";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import * as ModuleManager from "@/firebase/ModuleManager";
import { DocumentReference } from "firebase/firestore";
import * as Snackbars from "@/utils/Snackbars";
import * as SecureatServerApi from "@/utils/SecureatServerApi";

export default defineComponent({
  expose: ["search", "onSearchTextChange"],

  async setup() {
    await getDatabaseManager().initialize();
    await getStoreManager().initialize();

    return {
      visible: getStoreManager().getAvailableStoreOnHistory().length > 0,
    };
  },
  components: {
    SeSimpleTab,
    TemperatureResultTable,
    SeLoadingIndicator,
    TraceabilityResultTable,
    ActionResultTable,
    OilResultTable,
    TemperatureControlTable,
  },

  async mounted() {
    if (this.visible) {
      this.onTabChange();
      this.search();
    }
  },

  methods: {
    async exportPdf() {
      var refs = <any>this.$refs;
      refs.exportBtn.toggleLoading(true);
      let start = refs.search.getStart() as Date;
      let end = refs.search.getEnd();

      const timeDifference = end.getTime() - start.getTime();

      const dayDifference = Math.abs(timeDifference) / (1000 * 60 * 60 * 24);
      if (dayDifference > 30) {
        Snackbars.display(Lang.getI18N("date-range-too-long"), 4000, "error");
        return;
      }

      var result = await SecureatServerApi.generateTasksPDF(start, end);

      console.log(result);
      refs.exportBtn.toggleLoading(false);
    },
    onAnomalyFilter(value: boolean) {
      this.search();
    },
    onStoreChange(store: Store) {
      var refs = <any>this.$refs;

      for (let i = 0; i < 5; i++) {
        refs.tab.unhideIndex(i);
      }

      var database = getDatabaseManager().getDatabaseByReferenceFromCache(
        store.database
      );

      for (let moduleRef of store.hidden_modules) {
        this.hideModule(moduleRef);
      }
      for (let moduleRef of database.hidden_modules) {
        this.hideModule(moduleRef);
      }

      this.completeDisplay = false;
    },

    hideModule(moduleRef: DocumentReference) {
      var refs = <any>this.$refs;
      let module = ModuleManager.getModuleFromRef(moduleRef);
      var modEnum = module.toEnum();

      switch (modEnum) {
        case ModuleEnum.Temperatures:
          refs.tab.hideIndex(0);
          break;
        case ModuleEnum.Traceability:
          refs.tab.hideIndex(1);
          break;

        case ModuleEnum.Oils:
          refs.tab.hideIndex(2);
          break;

        case ModuleEnum.Action:
          refs.tab.hideIndex(3);
          break;

        case ModuleEnum.TemperatureControl:
          refs.tab.hideIndex(4);
          break;
      }
    },
    onSearchTextChange(search: string) {
      let activePanel = this.getActivePanel();

      let zones = activePanel.querySelectorAll(".expendable-zone");

      for (let zone of zones) {
        let rows = zone.querySelectorAll("tr");

        let headerText = zone.parentNode.firstChild.textContent
          .trim()
          .toLowerCase();

        let flag1 = headerText.includes(search.toLocaleLowerCase());

        let all = true;

        for (let i = 1; i < rows.length; i++) {
          let row = rows[i];

          let name = row.innerText.trim();

          if (!name.toLowerCase().includes(search.toLowerCase()) && !flag1) {
            row.style.display = "none";
          } else {
            row.style.display = "";
            all = false;
          }

          if (all) {
            zone.parentNode.style.display = "none";
          } else {
            zone.parentNode.style.display = "";
          }
        }
      }
    },

    toggleExpend() {
      this.expend = !this.expend;

      for (let panel of this.getAllPanels()) {
        let tableList = panel.childNodes[0].childNodes;

        for (let list of tableList) {
          if (list.tagName == "DIV") {
            let zone = list.querySelector(".expendable-zone");
            let btn = list.querySelector(".expend-btn");

            if (this.expend) {
              zone.classList.remove("close");
              btn.classList.add("rotate");
            } else {
              zone.classList.add("close");
              btn.classList.remove("rotate");
            }
          }
        }
      }
    },

    sortTasks(tasks: Task[], module: ModuleEnum): Task[] {
      var res = tasks
        .filter((x) => x.module == module)
        .sort((x, y) => {
          // If x.completed_timestamp is null, place it at the end
          if (x.completed_timestamp === null) {
            return 1;
          }
          // If y.completed_timestamp is null, place it at the end
          if (y.completed_timestamp === null) {
            return -1;
          }
          // Otherwise, sort by completed_timestamp in descending order
          return y.completed_timestamp.seconds - x.completed_timestamp.seconds;
        });

      if (this.displayAnomalies) {
        res = res.filter(
          (x) =>
            (x.result && !x.result.isValid()) ||
            TaskManager.getTaskStatus(x) == "late"
        );
      }

      return res;
    },
    async search() {
      this.temperatureTasks = [];
      this.traceabilityTasks = [];
      this.cleaningTasks = [];
      this.oilTasks = [];
      this.temperatureControlTasks = [];

      let currentStore = this.getCurrentStore();

      if (currentStore == null) {
        return;
      }

      this.completeDisplay = true;

      await nextTick();

      let refs = <any>this.$refs;
      refs.tab.selectFirstIndex(0);

      let start = refs.search.getStart() as Date;
      let end = refs.search.getEnd();

      const timeDifference = end.getTime() - start.getTime();

      const dayDifference = Math.abs(timeDifference) / (1000 * 60 * 60 * 24);
      if (dayDifference > 30) {
        Snackbars.display(Lang.getI18N("date-range-too-long"), 4000, "error");
        return;
      }

      let modules = [];

      modules.push(ModuleEnum.Temperatures);
      modules.push(ModuleEnum.Traceability);
      modules.push(ModuleEnum.Action);
      modules.push(ModuleEnum.Oils);
      modules.push(ModuleEnum.TemperatureControl);

      let displayedStores = [];

      displayedStores.push(currentStore);

      let employeeManager = getEmployeeManager();

      let results = await TaskManager.searchTasks(
        "",
        start,
        end,
        displayedStores,
        modules
      );

      for (let result of results) {
        if (result.completed_employee != null) {
          let target = await employeeManager.getEmployee(
            result.completed_employee!
          );

          if (target != null) {
            result.employee = target;
          }
        }
      }

      this.temperatureTasks = this.sortTasks(results, ModuleEnum.Temperatures);
      this.traceabilityTasks = this.sortTasks(results, ModuleEnum.Traceability);
      this.cleaningTasks = this.sortTasks(results, ModuleEnum.Action);
      this.oilTasks = this.sortTasks(results, ModuleEnum.Oils);
      this.temperatureControlTasks = this.sortTasks(
        results,
        ModuleEnum.TemperatureControl
      );
    },

    getCurrentStore(): Store {
      let refs = <any>this.$refs;
      return refs.search.getSelectedStore();
    },
    getActivePanel() {
      let refs = <any>this.$refs;

      let index = refs.tab.getSelectedIndex();

      switch (index) {
        case 0:
          return refs.temperaturePanel;
        case 1:
          return refs.traceabilityPanel;
        case 2:
          return refs.oilsPanel;
        case 3:
          return refs.cleaningPanel;
        case 4:
          return refs.temperatureControlPanel;
      }
    },

    getAllPanels() {
      let refs = <any>this.$refs;

      return [
        refs.temperaturePanel,
        refs.traceabilityPanel,
        refs.cleaningPanel,
        refs.oilsPanel,
        refs.temperatureControlPanel,
      ];
    },
    onTabChange() {
      let refs = <any>this.$refs;

      for (let panel of this.getAllPanels()) {
        panel.style.display = "none";
      }
      this.getActivePanel().style.display = "";

      refs.search.resetTextSearch();
    },
  },

  data() {
    let temperatureTasks = [] as Task[];
    let traceabilityTasks = [] as Task[];
    let cleaningTasks = [] as Task[];
    let oilTasks = [] as Task[];
    let temperatureControlTasks = [] as Task[];

    return {
      temperatureTasks: temperatureTasks,
      traceabilityTasks: traceabilityTasks,
      cleaningTasks: cleaningTasks,
      temperatureControlTasks: temperatureControlTasks,
      oilTasks: oilTasks,
      Lang: Lang,
      completeDisplay: false,
      expend: false,
      displayAnomalies: false,
    };
  },
});
