import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  orderBy,
  limitToLast,
  DocumentReference,
  limit,
} from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { getState } from "../pinia/AppState";

import Store from "../model/Store";
import User from "../model/User";
import Room from "../model/Room";
import { Role } from "../model/User";
import Probe from "@/model/Probe";
import Employee from "@/model/Employees/Employee";
import Task from "@/model/Task";
import * as ElementManager from "@/firebase/ElementManager";
import * as TaskManager from "@/firebase/TaskManager";
import Database from "@/model/Database";
import StoreElement from "@/model/Elements/StoreElement";
import StoreLabel from "@/model/Labels/StoreLabel";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import * as LabelsManager from "@/firebase/LabelsManager";
import TaskType from "@/model/Tasks/TaskType";

export async function modifyTaskType(taskType: TaskType) {
  await setDoc(taskType.ref!, taskType.toFirestore());
}
export async function getAllTaskTypes(store: Store): Promise<TaskType[]> {
  var storeTypes = await getStoreTaskTypes(store);
  var databaseTypes = await getDatabaseTaskTypes(store.database?.id!);

  return storeTypes.concat(databaseTypes);
}
export async function getDatabaseTaskTypes(databaseId: string) {
  const query = await getDocs(
    collection(Firebase.firestore, "databases/" + databaseId + "/task_types")
  );

  let results: TaskType[] = query.docs.map((x) =>
    TaskType.fromFirestore(x, false)
  );

  return results;
}
export async function getStoreTaskTypes(store: Store) {
  const elementsQuery = await getDocs(
    collection(Firebase.firestore, "stores/" + store.ref?.id + "/task_types")
  );

  let results: TaskType[] = elementsQuery.docs.map((x) =>
    TaskType.fromFirestore(x, true)
  );

  return results;
}

export async function createStoreTaskType(store: Store, type: TaskType) {
  let state = getState();
  let ref = await addDoc(
    collection(Firebase.firestore, "stores/" + store.ref?.id + "/task_types"),
    type.toFirestore()
  );

  type.ref = ref;
}
export async function createDatabaseTaskType(
  databaseId: string,
  type: TaskType
) {
  let state = getState();
  let ref = await addDoc(
    collection(Firebase.firestore, "databases/" + databaseId + "/task_types"),
    type.toFirestore()
  );

  type.ref = ref;
}
