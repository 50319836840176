import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Value = _resolveComponent("Value")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { title: "Tasks" }, {
    header: _withCtx(() => [
      _createVNode(_component_Tab, {
        onSwitch: _ctx.onFilterChange,
        labels: ['Completed', 'Late', 'Pending']
      }, null, 8, ["onSwitch"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Table, null, {
          columns: _withCtx(() => [
            _createVNode(_component_Column, null, {
              default: _withCtx(() => [
                _createTextVNode("Task Name")
              ]),
              _: 1
            }),
            (_ctx.filter == _ctx.TaskStateEnum.Completed)
              ? (_openBlock(), _createBlock(_component_Column, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode("Completed employee")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, null, {
              default: _withCtx(() => [
                _createTextVNode("Time")
              ]),
              _: 1
            }),
            _createVNode(_component_Column, null, {
              default: _withCtx(() => [
                _createTextVNode("Module")
              ]),
              _: 1
            })
          ]),
          rows: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTasks(_ctx.filter), (task) => {
              return (_openBlock(), _createBlock(_component_Row, {
                key: task.ref.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Value, { class: "hbold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(task.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  (_ctx.filter == _ctx.TaskStateEnum.Completed)
                    ? (_openBlock(), _createBlock(_component_Value, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getCompletedEmployee(task)), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Value, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTaskDate(task)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_Value, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Badge, {
                        color: _ctx.getModuleColor(task.module)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Lang.getI18N(task.getModuleName())), 1)
                        ]),
                        _: 2
                      }, 1032, ["color"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}