import { DocumentReference } from "firebase/firestore";
import TaskResult from "./TaskResult";
import Element from "@/model/Elements/Element";
import PictureResult from "./PictureResult";

export default class TraceabilityResult extends TaskResult {
  public isValid(): boolean {
    return true;
  }
  element: Element | null;
  pictures: PictureResult[] = [];

  constructor(
    details: string,
    status: string,
    timestamp: Date | null,
    element: Element | null,
    pictures: PictureResult[]
  ) {
    super(details, status, timestamp);
    this.element = element;
    this.pictures = pictures;
  }
}
