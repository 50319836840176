
import { RouterLink, RouterView } from "vue-router";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { getAuth, signInWithCustomToken, getIdToken } from "firebase/auth";
import FirebaseConfig from "./firebase/FirebaseConfig";
import { defineComponent } from "@vue/runtime-core";
import { getState } from "./pinia/AppState";
import NavPanel from "./components/nav/NavPanel.vue";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getUserManager } from "@/firebase/UserManager";
import User from "./model/User";
import { computed, watch } from "vue";
import * as StoreManager from "@/firebase/StoreManager";
import * as ModuleManager from "@/firebase/ModuleManager";
import { ModifierFlags } from "typescript";

import * as Modals from "@/utils/Modals";

export default defineComponent({
  components: {
    NavPanel,
  },

  setup() {
    const state = getState();

    // Get browser language (considering different browser implementations)
    const browserLang =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      "en";

    // Check if language starts with 'fr' (covers fr-FR, fr-CA, etc.)
    state.lang = browserLang.toLowerCase().startsWith("fr") ? "FR" : "EN";

    let navState = getNavigationState();
    let route = useRoute();

    let auth = getAuth();

    let windowMain = <any>window;
    windowMain.popStateDetected = false;
    windowMain.addEventListener("popstate", () => {
      windowMain.popStateDetected = true;
    });

    auth.onAuthStateChanged(async function (user) {
      if (state.logoutRequest) {
        return;
      }

      if (user) {
        if (!state.newAccount) {
          getUserManager().performLogin(user);
        }
      } else {
        let state = getState();

        state.resetManagers();

        if (state.token == null) {
          navState.navigate("login");
        } else {
          try {
            let userCredential = await signInWithCustomToken(
              auth,
              state.token!
            );

            const user = userCredential.user;

            let userData = await getUserManager().getUser(
              auth.currentUser!.uid
            );

            state.userData = userData;

            state.token = null;
          } catch {
            Modals.open(
              "Unable to authentificate using SecurEat token.",
              "Error"
            );
          }
        }
      }
    });
    return {
      state: state,
      route: route, // NjNtHvr6zQ4Cs42Tkqyo
    };
  },
});
