import { Timestamp } from "firebase/firestore";
import * as DateUtils from "../../utils/DateUtils";

export default class PunchCardEntry {
  timestamp: Date | null;
  is_in: boolean;
  selfie_url: string | null;
  timestamp_modified: Date | null;

  constructor(
    timestamp: Date | null,
    is_in: boolean,
    selfie_url: string,
    timestamp_modified: Date | null = null
  ) {
    this.timestamp = timestamp;
    this.is_in = is_in;
    this.selfie_url = selfie_url;
    this.timestamp_modified = timestamp_modified;
  }

  getLabel() {
    return DateUtils.formatTime(this.getTimestamp());
  }
  getColor() {
    return this.is_in ? "#4ad45f" : "#bc51d6";
  }
  public getTimestamp() {
    return this.timestamp_modified ? this.timestamp_modified : this.timestamp;
  }

  public static fromFirestore(data: any): PunchCardEntry {
    let result = new PunchCardEntry(
      data.timestamp ? data.timestamp.toDate() : null,
      data.is_in,
      data.selfie_url ? data.selfie_url : null,
      data.timestamp_modified ? data.timestamp_modified.toDate() : null
    );
    return result;
  }
  public toFirestore() {
    return {
      timestamp: this.timestamp,
      is_in: this.is_in,
      selfie_url: this.selfie_url,
      timestamp_modified: this.timestamp_modified,
    };
  }
}
