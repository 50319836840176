import { defineStore } from "pinia";
import StoreElement from "@/model/Elements/StoreElement";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import { ProfilingChartType } from "@/components/home/ProfilingChartType";
import { getStoreManager } from "@/firebase/StoreManager";
import Store from "@/model/Store";
import Task from "@/model/Task";
import { SnapshotListener } from "@/utils/SnapshotListener";
import {
  collection,
  DocumentReference,
  query,
  where,
} from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import * as ModuleManager from "@/firebase/ModuleManager";
import { TaskStateEnum } from "@/model/Tasks/TaskStateEnum";
import { list } from "pdfkit";
import Employee from "@/model/Employees/Employee";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import * as TaskManager from "@/firebase/TaskManager";
import { getElementManager } from "@/firebase/ElementManager";
import { ModuleEnum } from "@/model/ModuleEnum";
import TemperatureResult from "@/model/TaskResults/TemperatureResult";
import EmployeeRole from "@/model/Employees/EmployeeRole";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { ElementTemperatureStateEnum } from "@/components/home/ElementTemperatureStateEnum";
import OilResult from "@/model/TaskResults/OilResult";
import Communication from "@/model/Communication/Communication";
import { getCommunicationManager } from "@/firebase/CommunicationManager";
import CommunicationResult from "@/model/Communication/CommunicationResult";
import TrainingResult from "@/model/Trainings/TrainingResult";
import Training from "@/model/Trainings/Training";
import { getTrainingManager } from "@/firebase/TrainingManager";
import { getState } from "../AppState";
import EmployeePunchCard from "@/model/Employees/EmployeePunchCard";
import * as DateUtils from "@/utils/DateUtils";
import PunchCardPair from "@/model/Employees/PunchCardPair";
import PunchCardEntry from "@/model/Employees/PunchCardEntry";

interface HomeState {
  stores: Store[];
  selectedStore: Store | null;
  tasksOfDay: Map<Store, SnapshotListener<Task>>;
  loading: boolean;
  employees: Employee[];
  employeeRoles: EmployeeRole[];
  deliveryElements: Map<StoreElement, ElementTemperatureStateEnum>;
  fridgeElements: Map<StoreElement, ElementTemperatureStateEnum>;
  oilElements: Map<StoreElement, OilResult>;
  communications: Communication[];
  communicationResults: Map<Communication, CommunicationResult[]>;
  trainings: Training[];
  trainingResults: Map<Training, TrainingResult[]>;

  punchCards: Map<string, EmployeePunchCard>;
}

export const getHomeState = defineStore("homeState", {
  state: (): HomeState => {
    return {
      stores: [],
      selectedStore: null,
      tasksOfDay: new Map<Store, SnapshotListener<Task>>(),
      loading: false,
      employees: [],
      employeeRoles: [],
      fridgeElements: new Map(),
      deliveryElements: new Map(),
      oilElements: new Map(),
      communications: [],
      communicationResults: new Map(),
      trainings: [],
      trainingResults: new Map(),
      punchCards: new Map(),
    };
  },
  actions: {
    async setup() {
      await ModuleManager.loadModules();

      await getStoreManager().initialize();

      this.stores = getStoreManager()
        .getStores(true)
        .filter((x) => x.active == true);

      if (this.selectedStore == null) {
        this.selectedStore = this.stores[0];
      }

      this.onStoreChanged(this.selectedStore);
    },
    getEmployeeName(ref: DocumentReference) {
      const employee = this.employees.find((x) => x.ref.id == ref.id);
      if (employee) {
        return employee.name + " " + employee.surname;
      }
      return "";
    },

    getRoleName(ref: DocumentReference) {
      if (ref == null) {
        return "No role";
      }
      var role = this.employeeRoles.find((x) => x.ref.id == ref.id);

      if (role) {
        return role.name;
      } else {
        return "Unknown role";
      }
    },
    async loadElements() {
      this.fridgeElements = new Map();

      var temperatureTasks = this.tasksOfDay
        .get(this.selectedStore)
        .items.filter((x) => x.module == ModuleEnum.Temperatures);

      for (let temperatureTask of temperatureTasks) {
        var results = await TaskManager.getTaskResults(
          temperatureTask,
          this.selectedStore
        );

        var temperatureResults: TemperatureResult[] = results
          .filter((x) => x instanceof TemperatureResult)
          .map((x) => x as TemperatureResult);

        for (let result of temperatureResults) {
          var rangeStatus = result.getElementTemperatureStateEnum();

          if (result.temperature == null) {
            continue;
          }
          if (result.element.getTemperatureDeliveryItem()) {
            this.deliveryElements.set(result.element, rangeStatus);
          } else {
            if (this.fridgeElements.has(result.element)) {
              var oldRangeStatus = this.fridgeElements.get(result.element);

              if (
                oldRangeStatus == ElementTemperatureStateEnum.OutOfRange &&
                rangeStatus == ElementTemperatureStateEnum.InRange
              ) {
                rangeStatus = ElementTemperatureStateEnum.Mixed;
              }
              if (
                oldRangeStatus == ElementTemperatureStateEnum.InRange &&
                rangeStatus == ElementTemperatureStateEnum.OutOfRange
              ) {
                rangeStatus = ElementTemperatureStateEnum.Mixed;
              }
            }
            this.fridgeElements.set(result.element, rangeStatus);
          }
        }
      }

      var oilTasks = this.tasksOfDay
        .get(this.selectedStore)
        .items.filter((x) => x.module == ModuleEnum.Oils);

      for (let task of oilTasks) {
        var results = await TaskManager.getTaskResults(
          task,
          this.selectedStore
        );

        var oilResults: OilResult[] = results
          .filter((x) => x instanceof OilResult)
          .map((x) => x as OilResult);

        for (let result of oilResults) {
          this.oilElements.set(result.element, result);
        }
      }
    },

    async loadPunchCards() {
      var year = new Date().getFullYear();

      for (let employee of this.employees.filter(
        (x) => x.active
      ) as Employee[]) {
        var punchCard = await getEmployeeManager().getEmployeePunchCard(
          employee.ref.id,
          year
        );

        this.punchCards.set(employee.ref.id, punchCard);
      }
    },

    getEmployeePunchCard(employee: Employee): EmployeePunchCard {
      return this.punchCards.get(employee.ref.id);
    },

    deletePunchCard(employee: Employee, date: Date, entry: PunchCardEntry) {
      var key = DateUtils.formatDateAlphaSort(date);

      var punchCard = getHomeState().getEmployeePunchCard(employee);
      var data = punchCard.data.get(getHomeState().selectedStore.ref.id);

      if (!data.has(key)) {
        return;
      }

      var entries = data.get(key);

      var index = entries.indexOf(entry);

      if (index != -1) {
        entries.splice(index, 1);
      }
    },
    getPreviousPunchCard(
      employee: Employee,
      punchCardEntry: PunchCardEntry,
      date: Date
    ) {
      var key = DateUtils.formatDateAlphaSort(date);

      var punchCard = getHomeState().getEmployeePunchCard(employee);

      punchCard.sort();

      var data = punchCard.data.get(getHomeState().selectedStore.ref.id);

      var last = null;

      for (let key of data.keys()) {
        if (key > key) {
          break;
        }

        if (data.get(key).length == 0) {
          continue;
        }

        last = data.get(key)[data.get(key).length - 1];
      }

      return last;
    },
    addPunchCard(employee: Employee, date: Date) {
      var key = DateUtils.formatDateAlphaSort(date);

      var punchCard = getHomeState().getEmployeePunchCard(employee);

      punchCard.sort();

      if (!punchCard.data.has(getHomeState().selectedStore.ref.id)) {
        punchCard.data.set(getHomeState().selectedStore.ref.id, new Map());
      }
      var data = punchCard.data.get(getHomeState().selectedStore.ref.id);

      if (!data.has(key)) {
        data.set(key, []);
      }

      var isIn = true;

      for (let k of data.keys()) {
        if (k > key) {
          break;
        }

        if (data.get(k).length == 0) {
          continue;
        }

        var last = data.get(k)[data.get(key).length - 1];

        if (last) {
          isIn = !last.is_in;
        }
      }

      var entry = new PunchCardEntry(null, isIn, null, new Date());

      data.get(key).push(entry);

      return entry;
    },
    getPunchCardEntries(employee: Employee, date: Date): PunchCardEntry[] {
      var key = DateUtils.formatDateAlphaSort(date);

      if (!this.punchCards) {
        return [];
      }
      if (!this.punchCards.has(employee.ref.id)) {
        return [];
      }

      var card: EmployeePunchCard = this.punchCards.get(employee.ref.id);

      var storeData = card.data.get(this.selectedStore.ref.id);

      if (!storeData) {
        return [];
      }

      if (!storeData.has(key)) {
        return [];
      }

      var entries = storeData.get(key);

      var results: PunchCardEntry[] = [];

      for (let entry of entries) {
        results.push(entry);
      }
      return results;
    },

    async onStoreChanged(store: Store) {
      this.loading = true;

      if (!this.tasksOfDay.has(store)) {
        const now = new Date();

        const begin = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds

        const end = now;

        const tasksCollectionRef = collection(
          Firebase.firestore,
          `/stores/${store.ref!.id}/tasks`
        );

        const q = query(
          tasksCollectionRef,
          where("start", ">=", begin),
          where("start", "<=", end)
        );

        const listener = new SnapshotListener<Task>(
          (data: any) => Task.fromFirestore(data),
          q,
          null
        );

        await listener.ensureInit();

        this.tasksOfDay.set(store, listener);
      }

      this.employees = await getEmployeeManager().getEmployees(store.ref);

      this.employeeRoles = await getDatabaseManager().getEmployeeRoles(
        store.database
      );

      await getElementManager().ensureDatabaseElementListener(
        store.database.id
      );
      await getElementManager().ensureStoreElementListener(store.ref.id);

      await this.loadElements();

      this.communications =
        await getCommunicationManager().getActiveCommunications(store);

      for (let communication of this.communications) {
        var results = await getCommunicationManager().fetchCommunicationResults(
          communication
        );

        this.communicationResults.set(communication, results);
      }

      var user = getState().userData;
      this.trainings = await getTrainingManager().getActiveTrainings(
        user,
        store
      );

      for (let training of this.trainings) {
        var results = await getTrainingManager().fetchTrainingResults(training);

        this.trainingResults.set(training, results);
      }

      await this.loadPunchCards();

      this.loading = false;
    },
  },
});
