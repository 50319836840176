export function parseRGBA(rgbaString: string) {
  // Regular expression to match rgba color strings
  const rgbaRegex =
    /^rgba?\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*(?:,\s*(0|0?\.\d+|1(\.0)?)\s*)?\)$/;

  // Match the string against the regex
  const match = rgbaString.match(rgbaRegex);

  // If the match is null, the input string is not a valid rgba string
  if (!match) {
    throw new Error("Invalid RGBA string");
  }

  // Extract the components and convert them to numbers
  const red = parseInt(match[1], 10);
  const green = parseInt(match[2], 10);
  const blue = parseInt(match[3], 10);
  const alpha = match[4] !== undefined ? parseFloat(match[4]) : 1; // Default alpha to 1 if not provided

  return { red, green, blue, alpha };
}

export function rgbToHex(r: number, g: number, b: number) {
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}
export function changeHexOpacity(hex: string, opacity: number): string {
  // Validate opacity
  if (opacity < 0 || opacity > 1 || isNaN(opacity)) {
    throw new Error("Opacity must be a number between 0 and 1.");
  }

  // Remove the hash symbol if present
  let cleanHex = hex.replace("#", "");

  // Expand shorthand form (e.g., '03F') to full form ('0033FF')
  if (cleanHex.length === 3 || cleanHex.length === 4) {
    cleanHex = cleanHex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Now cleanHex should be either 6 or 8 characters
  if (cleanHex.length !== 6 && cleanHex.length !== 8) {
    throw new Error(
      "Invalid hex color format. Use '#RGB', '#RGBA', '#RRGGBB', or '#RRGGBBAA'."
    );
  }

  // Extract RGB components
  const r = cleanHex.substring(0, 2);
  const g = cleanHex.substring(2, 4);
  const b = cleanHex.substring(4, 6);

  // Convert opacity to hex
  const a = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, "0");

  // Return the new hex color with opacity
  return `#${r}${g}${b}${a}`.toUpperCase();
}
