import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e35aac2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-header-bar" }
const _hoisted_2 = { class: "flex align-center justify-start" }
const _hoisted_3 = { class: "no-mobile text-blue p6 f14" }
const _hoisted_4 = { class: "flex col g2 align-center justify-start" }
const _hoisted_5 = { class: "nav-header-title" }
const _hoisted_6 = {
  style: {"white-space":"nowrap"},
  class: "text-muted f12"
}
const _hoisted_7 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_LanguageSelect = _resolveComponent("LanguageSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.backButton == 'true')
        ? (_openBlock(), _createBlock(_component_SeFadeButton, {
            key: 0,
            class: "no-mobile",
            iconRight: true,
            icon: "ico/arrow-right.svg",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.Lang.getI18N("back")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.appState.customStoreId == null)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            id: "navDisplay",
            src: "ico/menu.svg",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.displayNavBar()))
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.subtitle), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_LanguageSelect)
    ])
  ]))
}