import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import * as ImageUtils from "@/utils/ImageUtils";
import Database from "../Database";

export default abstract class Element extends SecureatSnapshot {
  constructor(ref: DocumentReference) {
    super(ref);
  }

  public getIllustrationUrlWithWatermark(database: Database) {
    if (database.illustration_url != null) {
      let url = ImageUtils.getIllustrationUrlWithWatermark(
        this.getIllustrationUrl()!,
        100,
        100,
        database.ref.id
      );
      return url;
    } else {
      return this.getIllustrationUrl();
    }
  }

  public abstract getActive(): boolean | null;

  public abstract getIllustrationPath(): string | null;

  public abstract getIllustrationUrl(): string | null;

  public abstract getLabelsModuleActive(): boolean | null;

  public abstract getMaximumTemperature(): number | null;

  public abstract getMinimumTemperature(): number | null;

  public abstract getName(): string | null;

  public abstract getTemperaturePicture(): boolean | null;

  public abstract getTemperatureDeliveryItem(): boolean | null;

  public abstract getTemperaturesModuleActive(): boolean | null;

  public abstract getTraceabilityModuleActive(): boolean | null;

  public abstract getOilsModuleActive(): boolean | null;

  public abstract getMaximumPolarity(): number | null;

  public abstract getType(): DocumentReference | null;

  public abstract getTemperatureControlModuleActive(): boolean | null;

  public abstract getReheatingTemperature(): number | null;

  public abstract getCoolingTemperature(): number | null;

  public abstract getAttachments(): any | null;

  public abstract getOilsRequirePicture(): boolean | null;

  public isLocal() {
    return false;
  }

  public getIcon() {
    let url = this.getIllustrationUrl();
    return url == null || url.trim() == "" ? "/ico/equipement.svg" : url;
  }

  public abstract toFirestore(): any;

  public toJson(): Record<string, any> {
    return {};
  }
}
