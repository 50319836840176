import { defineStore } from "pinia";
import User from "@/model/User";
import Store from "@/model/Store";
import Task from "@/model/Task";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import Database from "@/model/Database";
import Element from "@/model/Elements/Element";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import { getState } from "../AppState";
import { DocumentReference, getDoc, getDocFromCache } from "firebase/firestore";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { getElementManager } from "@/firebase/ElementManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Employee from "@/model/Employees/Employee";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import EmployeeRole from "@/model/Employees/EmployeeRole";
import { getUserManager } from "@/firebase/UserManager";
import { KooveaSticker } from "@/model/Koovea/KooveaSticker";
import Probe from "@/model/Probe";
import { getProbesManager } from "@/firebase/ProbeManager";
import * as SecureatServerApi from "@/utils/SecureatServerApi";

interface StoreDatabaseState {
  store: Store | null;
  initialStore: Store | null;
  tabIndex: number;
  database: Database | null;
  elements: Element[] | null;
  tasks: TaskConfiguration[] | null;
  elementSearch: string;
  taskSearch: string;
  scrollY: number;
  displayUnactives: boolean;
  employees: Employee[];
  roles: EmployeeRole[];
  owners: User[] | null;
  probes: Probe[];
  kooveaStickers: KooveaSticker[];
  selectedSticker: KooveaSticker | null;
}

export const getStoreDatabaseState = defineStore("storeDatabaseState", {
  state: (): StoreDatabaseState => {
    return {
      store: null,
      initialStore: null,
      tabIndex: 0,
      database: null,
      elements: [],
      tasks: [],
      elementSearch: "",
      taskSearch: "",
      scrollY: 0,
      displayUnactives: false,
      employees: [],
      owners: null,
      roles: [],
      probes: [],
      kooveaStickers: [],
      selectedSticker: null,
    };
  },
  actions: {
    set(store: Store) {
      this.store = Store.clone(store);
      this.initialStore = store;
    },
    getRoleName(ref: DocumentReference) {
      var role = this.roles.find((x) => x.ref.id == ref.id);

      if (!role) {
        console.log("role " + ref.id + "  is null");
        return null;
      }

      return role.name;
    },
    async setup() {
      this.kooveaStickers = null;
      let appState = getState();

      if (appState.customStore != null) {
        this.store = appState.customStore;
      }

      await getDatabaseManager().initialize();
      await getStoreManager().initialize();

      if (this.store.database != null) {
        this.database = getDatabaseManager().getDatabaseByReferenceFromCache(
          this.store.database
        );

        if (this.database == null) {
          this.database = await getDatabaseManager().getDatabaseByReference(
            this.store!.database!
          );
        }
      }
    },
  },
});
