
import * as StoreManager from "../../firebase/StoreManager";
import { getStoreState } from "@/pinia/stores/StoreState";
import { useRouter } from "vue-router";
import { getState } from "@/pinia/NavigationState";
import { defineComponent } from "vue";
import Store from "@/model/Store";
import ToggleSwitch from "../global/ToggleSwitch.vue";
import { getDistributorState } from "@/pinia/distributors/DistributorState";
import DistributorShopItem from "@/model/Shop/DistributorShopItem";
import * as Firebase from "@/firebase/Firebase";
import { collection, doc } from "firebase/firestore";
import * as DOMUtils from "@/utils/DOMUtils";
import * as UploadManager from "@/model/Uploads/UploadManager";
import DistributorShopItemCard from "./DistributorShopItemCard.vue";
import { getShopState } from "@/pinia/shop/ShopState";
import * as CollectionUtils from "@/utils/CollectionUtils";
import { getShopManager } from "@/firebase/ShopManager";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  components: { DistributorShopItemCard },
  props: [],
  setup() {
    return {
      state: getShopState(),
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  methods: {
    moveItemRight(item: DistributorShopItem) {
      CollectionUtils.moveForward(item, this.state.shopItems);
    },
    moveItemLeft(item: DistributorShopItem) {
      CollectionUtils.moveBackward(item, this.state.shopItems);
    },
    addShopItem() {
      let index = this.state.shopItems.length;

      this.state.shopItems.push(
        new DistributorShopItem(
          doc(
            collection(
              Firebase.firestore,
              this.state.distributor!.ref.path,
              "shop"
            )
          ),
          true,
          "",
          "",
          100,
          null,
          index
        )
      );
    },
  },
});
