import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex wrap g0" }
const _hoisted_2 = {
  class: "header-text",
  style: {"width":"180px"}
}
const _hoisted_3 = { style: {"font-size":"15px"} }
const _hoisted_4 = {
  class: "header-text",
  style: {"width":"350px"}
}
const _hoisted_5 = { style: {"font-size":"15px"} }
const _hoisted_6 = {
  key: 0,
  class: "header-text no-mobile",
  style: {"width":"200px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskStatus = _resolveComponent("TaskStatus")!
  const _component_TraceabilityResultRow = _resolveComponent("TraceabilityResultRow")!
  const _component_SeTable = _resolveComponent("SeTable")!
  const _component_PostponedTaskRow = _resolveComponent("PostponedTaskRow")!
  const _component_SeExpendable = _resolveComponent("SeExpendable")!

  return (_openBlock(), _createBlock(_component_SeExpendable, { headerSize: 20 }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("b", _hoisted_3, _toDisplayString(_ctx.TaskManager.getTaskDateString(_ctx.task!).replace(" ", " - ")), 1)
        ]),
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("b", _hoisted_5, _toDisplayString(_ctx.task!.name), 1)
        ]),
        (_ctx.task.employee != null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.task!.employee?.name) + " " + _toDisplayString(_ctx.task!.employee?.surname), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.TaskManager.isTaskLate(_ctx.task!))
        ? (_openBlock(), _createBlock(_component_TaskStatus, {
            key: 0,
            status: "late"
          }))
        : _createCommentVNode("", true),
      (_ctx.task?.postponed_task != null)
        ? (_openBlock(), _createBlock(_component_TaskStatus, {
            key: 1,
            status: "postponed"
          }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.task?.postponed_task == null)
        ? (_openBlock(), _createBlock(_component_SeTable, {
            key: 0,
            widths: [5, 20, 70],
            columns: ['', _ctx.Lang.getI18N('name'), _ctx.Lang.getI18N('picture')]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result) => {
                return (_openBlock(), _createBlock(_component_TraceabilityResultRow, { result: result }, null, 8, ["result"]))
              }), 256))
            ]),
            _: 1
          }, 8, ["columns"]))
        : (_openBlock(), _createBlock(_component_SeTable, {
            key: 1,
            widths: [5, 5, 5],
            columns: [
        _ctx.Lang.getI18N('postpone-date'),
        _ctx.Lang.getI18N('period'),
        _ctx.Lang.getI18N('employee'),
      ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PostponedTaskRow, { task: _ctx.task }, null, 8, ["task"])
            ]),
            _: 1
          }, 8, ["columns"]))
    ]),
    _: 1
  }))
}