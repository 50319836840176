
import { defineComponent } from "vue";
import Badge from "../newGlobal/Badge.vue";
import { getHomeState } from "@/pinia/home/HomeState";
import { TaskStateEnum } from "@/model/Tasks/TaskStateEnum";
import * as DateUtils from "@/utils/DateUtils";
import Task from "@/model/Task";
import * as TaskManager from "@/firebase/TaskManager";
import * as ColorUtils from "@/utils/ColorUtils";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  setup() {
    return {
      homeState: getHomeState(),
    };
  },
  methods: {
    getTasks(filter: TaskStateEnum): Task[] {
      const listener = this.homeState.tasksOfDay.get(
        this.homeState.selectedStore
      );
      if (listener) {
        if (filter == TaskStateEnum.Completed) {
          return listener.items.filter((x) => x.completed_timestamp != null);
        } else if (filter == TaskStateEnum.Late) {
          return listener.items.filter(
            (x) =>
              TaskManager.getExpectedCompletionDate(x) < new Date() &&
              x.completed_timestamp == null
          );
        } else if (filter == TaskStateEnum.Pending) {
          return listener.items.filter(
            (x) =>
              TaskManager.getExpectedCompletionDate(x) >= new Date() &&
              x.completed_timestamp == null
          );
        }
        return listener.items;
      }
      return [];
    },
    getCompletedEmployee(task: Task) {
      return this.homeState.getEmployeeName(task.completed_employee);
    },
    getTaskDate(task: Task) {
      if (task.completed_timestamp != null) {
        return DateUtils.formatTime(task.completed_timestamp.toDate());
      }

      if (this.filter == TaskStateEnum.Late) {
        return DateUtils.formatTime(
          TaskManager.getExpectedCompletionDate(task)
        );
      } else if (this.filter == TaskStateEnum.Pending) {
        return DateUtils.formatTime(task.start.toDate());
      }
    },
    onFilterChange(index: number) {
      switch (index) {
        case 0:
          this.filter = TaskStateEnum.Completed;
          break;
        case 1:
          this.filter = TaskStateEnum.Late;
          break;
        case 2:
          this.filter = TaskStateEnum.Pending;
          break;
      }
    },
    getModuleColor(module: string) {
      var color = null;

      switch (module) {
        case "actions":
          color = "#B75EFF";
          break;
        case "temperatures":
          color = "#706AFF";
          break;
        case "traceability":
          color = "#FF358F";
          break;
        case "labels":
          color = "#FF815E";
          break;
        case "oils":
          color = "#E8CB13";
          break;
        default:
          color = "#FF358F";
          break;
      }

      return color;
    },
  },
  data() {
    {
      var filter: TaskStateEnum = TaskStateEnum.Completed;
      return {
        Lang: Lang,
        filter: filter,
        DateUtils: DateUtils,
        TaskStateEnum: TaskStateEnum,
      };
    }
  },
});
