import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2e1151db"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "p12",
  style: {"background-color":"#f3fafa"}
}
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex col g0 justify-center" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { class: "text-muted" }
const _hoisted_6 = { class: "flex col p12" }
const _hoisted_7 = { class: "flex justify-between" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "grid single p12" }
const _hoisted_10 = { class: "text-muted no-mobile" }
const _hoisted_11 = { class: "flex align-center justify-center g6 text-muted no-mobile" }
const _hoisted_12 = { class: "flex col p12 grow h100" }
const _hoisted_13 = {
  key: 1,
  class: "p12"
}
const _hoisted_14 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilingChart = _resolveComponent("ProfilingChart")!
  const _component_SeCalendarRanged = _resolveComponent("SeCalendarRanged")!
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_WideCard = _resolveComponent("WideCard")!
  const _component_SeTab = _resolveComponent("SeTab")!

  return (_ctx.appState.connectedToApi)
    ? (_openBlock(), _createBlock(_component_SeTab, {
        key: 0,
        onTabChange: _ctx.onTabChange,
        ref: "tab",
        tabs: [
      _ctx.Lang.getI18N('admin.profiling'),
      _ctx.Lang.getI18N('admin.task_generation'),
      'Output',
    ],
        icons: ['/ico/profiling.svg', '/ico/profiling.svg', '/ico/profiling.svg'],
        style: {"margin":"24px 24px 0px 24px"}
      }, {
        "0": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, "Serveur (" + _toDisplayString(_ctx.projectId) + ")", 1),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.config.apiHost), 1)
              ])
            ]),
            _createVNode(_component_ProfilingChart)
          ])
        ]),
        "1": _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_SeCalendarRanged, { ref: "calendar" }, null, 512),
                _createVNode(_component_SeDropdown, {
                  search: true,
                  titleFunc: (store) => store.name,
                  style: {"min-width":"300px"},
                  ref: "storeSelect"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stores, (store) => {
                      return (_openBlock(), _createBlock(_component_SeDropdownItem, { value: store }, null, 8, ["value"]))
                    }), 256))
                  ]),
                  _: 1
                }, 8, ["titleFunc"])
              ]),
              _createVNode(_component_SeButton, {
                ref: "searchBtn",
                onClick: _ctx.search,
                icon: "/ico/search_white.svg"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Search")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taskGenerationIssues, (event) => {
                return (_openBlock(), _createBlock(_component_WideCard, {
                  title: _ctx.getEventName(event.type)
                }, {
                  subtitle: _withCtx(() => [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.getDateString(event)), 1)
                  ]),
                  body: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(event.message), 1)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 256))
            ])
          ])
        ]),
        "2": _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("textarea", {
              readonly: "",
              ref: "textarea",
              style: {"letter-spacing":"normal","resize":"none","height":"500px"},
              class: "output",
              type: "text"
            }, _toDisplayString(_ctx.output == null ? "" : _ctx.output.StandardOutput), 513)
          ])
        ]),
        _: 1
      }, 8, ["onTabChange", "tabs"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_13, [
        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.Lang.getI18N("admin.unable_connect_api")), 1)
      ]))
}