
import { defineComponent } from "vue";
import NavHeader from "@/components/nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import * as Lang from "@/i18n/lang";
import { getCommunicationsState } from "@/pinia/communications/CommunicationsState";
import Database from "@/model/Database";
import CommunicationList from "@/components/communication/CommunicationList.vue";
import { getCommunicationManager } from "@/firebase/CommunicationManager";
import { getCommunicationState } from "@/pinia/communications/CommunicationState";
import { getState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { Role } from "@/model/User";
export default defineComponent({
  components: {
    NavHeader,
    CommunicationList,
  },
  mounted() {
    if (this.state.selectedDatabase != null)
      this.onDatabaseChanged(this.state.selectedDatabase!);
  },
  methods: {
    addCommunication() {
      getCommunicationState().set(this.state.selectedDatabase!, null);
      getState().navigate("communication");
    },
    async onDatabaseChanged(database: Database) {
      this.state.communications =
        await getCommunicationManager().getCommunications(database.ref);
    },
  },
  async setup() {
    let state = getCommunicationsState();

    let userRole = getAppState().userData.role;
    await state.setup();

    return {
      state: state,
      userRole: userRole,
      Role: Role,
    };
  },
  data() {
    return { Lang: Lang };
  },
});
