import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "outer-circle",
    style: _normalizeStyle(_ctx.outerStyle)
  }, [
    _createElementVNode("div", {
      class: "inner-circle",
      style: _normalizeStyle(_ctx.innerStyle)
    }, null, 4)
  ], 4))
}