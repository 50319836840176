
import * as Lang from "@/i18n/lang";
import NavHeader from "../nav/NavHeader.vue";
import * as TaskManager from "@/firebase/TaskManager";
import dateFormat from "dateformat";
import { defineComponent } from "@vue/runtime-core";
import { getState } from "@/pinia/NavigationState";
import Task from "@/model/Task";
import WideCard from "@/components/global/WideCard.vue";
import Store from "@/model/Store";
import * as DateUtils from "@/utils/DateUtils";
import { ModuleEnum } from "@/model/ModuleEnum";
import { getTaskState } from "@/pinia/tasks/Task";

export default defineComponent({
  data() {
    return {
      TaskManager: TaskManager,
      Lang: Lang,
      dateFormat: dateFormat,
      ModuleEnum: ModuleEnum,
    };
  },
  components: {
    WideCard,
  },
  methods: {
    displayModal(pictureUrl: string) {
      let refs = <any>this.$refs;
      refs.modalImage.src = pictureUrl;
      refs.imageModal.open();
    },
  },
  async setup() {
    var state = getTaskState();

    let submissionDate = null;

    if (state.task!.completed_timestamp != null) {
      submissionDate = DateUtils.getDateFromSeconds(
        state.task!.completed_timestamp.seconds
      );
    }

    let endDate = DateUtils.getDateFromSeconds(
      state.task!.start.seconds + state.task!.duration * 60
    );

    let completed = state.task!.completed_timestamp != undefined;

    let completedEmployee = await TaskManager.getEmployeeFromTask(state.task!);

    let results = null;

    if (submissionDate != null) {
      results = await TaskManager.getTaskResults(state.task!, state.store!);
    }

    return {
      results: results,
      state: state,
      completed: completed,
      submissionDate: submissionDate,
      completedEmployee: completedEmployee,
      endDate: endDate,
    };
  },
});
