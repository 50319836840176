
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "vue-chartjs";
import * as chartConfig from "./TrainingChartConfig";
import { defineComponent, nextTick } from "vue";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import { getState as getAppState } from "@/pinia/AppState";
import CommunicationResult from "@/model/Communication/CommunicationResult";
import { group } from "console";
import TrainingResult from "@/model/Trainings/TrainingResult";
import { getTrainingStatsState } from "@/pinia/trainings/TrainingStatsState";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default defineComponent({
  name: "App",
  components: {
    Bar,
  },
  setup() {
    return {
      intervalId: null as any,
    };
  },
  data() {
    return chartConfig;
  },
  methods: {
    groupByDateIgnoringTime(results: TrainingResult[]): any {
      const grouped: any = {};

      results.forEach((result) => {
        if (
          result.completed_timestamp != undefined &&
          result.completed_timestamp != null
        ) {
          const dateKey = result.completed_timestamp
            .toISOString()
            .split("T")[0];

          if (!grouped[dateKey]) {
            grouped[dateKey] = [];
          }

          grouped[dateKey].push(result);
        }
      });

      const result: any = {};

      var state = getTrainingStatsState();

      const min: Date = new Date();

      min.setDate(min.getDate() - 10);

      let max: Date = new Date();

      min.setHours(0);
      min.setMinutes(0);
      min.setSeconds(0);

      max.setHours(23);
      max.setMinutes(59);
      max.setSeconds(59);

      const differenceInMillis = max.getTime() - min.getTime();

      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const differenceInDays = Math.floor(
        differenceInMillis / millisecondsPerDay
      );

      for (let i = differenceInDays; i >= 0; i--) {
        let d = new Date(max.getTime());
        d.setDate(max.getDate() - i);

        const dateKey = d.toISOString().split("T")[0];

        result[dateKey] = [];
      }

      for (let key of Object.keys(result)) {
        if (grouped[key]) {
          for (let val of grouped[key]) {
            result[key].push(val);
          }
        }
      }

      return result;
    },
  },
  async mounted() {
    var state = getTrainingStatsState();

    var grouped = this.groupByDateIgnoringTime(state.getResults());

    var refs = <any>this.$refs;

    if (refs.graph == null) {
      return;
    }

    nextTick(() => {
      const ctx = refs.graph.chart.getContext("2d");

      let labels: string[] = [];
      let values = [] as number[];

      for (let label of Object.keys(grouped)) {
        labels.push(label);
        values.push(grouped[label].length);
      }
      chartConfig.data.datasets[0].data = values;

      chartConfig.data.labels = labels;

      refs.graph.chart.data = chartConfig.data;
      ctx.chart.update();
    });
  },
  unmounted() {},
});
