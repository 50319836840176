
import { defineComponent, computed } from "vue";
import * as ColorsUtils from "@/utils/ColorUtils";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: "#454eff",
    },
  },
  setup(props) {
    const bannerStyle = computed(() => {
      if (props.color) {
        return {
          "--badge-bg-color": ColorsUtils.changeHexOpacity(props.color, 0.2),
          "--badge-bg-color2": ColorsUtils.changeHexOpacity(props.color, 0.1),
          "--badge-text-color": props.color,
          "--badge-border-color": ColorsUtils.changeHexOpacity(
            props.color,
            0.4
          ),
        };
      }
      return {};
    });

    return { bannerStyle };
  },
});
