export default abstract class TaskResult {
  details: string | null = null;
  status: string | null = null;
  timestamp: Date | null = null;

  constructor(
    details: string | null,
    status: string | null,
    timestamp: Date | null
  ) {
    this.details = details;
    this.status = status;
    this.timestamp = timestamp;
  }
  public abstract isValid(): boolean;
}
