import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "text-muted",
  style: {"font-weight":"bold"}
}
const _hoisted_2 = { class: "text-muted" }
const _hoisted_3 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_4 = {
  key: 1,
  class: "text-red"
}
const _hoisted_5 = { class: "text-muted" }
const _hoisted_6 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeLoadingIndicator = _resolveComponent("SeLoadingIndicator")!
  const _component_SeImage = _resolveComponent("SeImage")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createVNode(_component_SeImage, {
        maxWidth: "40",
        maxHeight: "40",
        src: _ctx.result.element.getIcon()
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SeLoadingIndicator, { size: 40 })
        ]),
        _: 1
      }, 8, ["src"])
    ]),
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.result.element.getName()), 1),
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.result.start_temperature) + "°C", 1),
    _createElementVNode("td", null, [
      (_ctx.result.end_temperature != null)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.valid())
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.result.end_temperature) + "°C", 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.result.end_temperature) + "°C (" + _toDisplayString(_ctx.result.target_temperature) + "°C)", 1))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.computeDuration()), 1),
    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.result.comment), 1)
  ]))
}