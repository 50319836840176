
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import AuditingModuleTab from "@/components/auditing/AuditingModuleTab.vue";
import Audit from "@/model/Auditing/Audit";
import { getState } from "../../pinia/NavigationState";
import { getAuditConfigurationState } from "@/pinia/audits/AuditConfigurationState";
import { compareObjects } from "@/utils/ObjectUtils";
import * as Snackbars from "@/utils/Snackbars";
import { getAuditManager } from "@/firebase/AuditManager";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  props: [],
  data() {
    return {
      Lang: Lang,
    };
  },
  async setup() {
    let state = getAuditConfigurationState();

    return { state };
  },
  methods: {
    async saveAudit() {
      console.log("save audit active : ", this.state.audit!.active);
      let refs = <any>this.$refs;

      refs.saveBtn.toggleLoading(true);

      if (!refs.submitForm.verify()) {
        refs.saveBtn.toggleLoading(false);
        return;
      }

      if (
        this.state.audit!.publishing_date === null &&
        this.state.audit!.published
      ) {
        this.state.audit!.publishing_date = new Date();
      }

      if (this.state.selectedDatabaseId === null) {
        refs.saveBtn.toggleLoading(false);
        return;
      }

      console.log(this.state.audit.ref);

      await this.state.audit.set();

      console.log("done");
      this.state.changeSaved = true;
      refs.saveBtn.toggleLoading(false);
      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
    async duplicateAudit() {
      if (this.state.selectedDatabaseId === null) {
        return;
      }

      Modals.openYesNo(
        "Voulez-vous vraiment dupliquer cet audit ?",
        "Secureat",
        "Oui",
        "Non"
      )
        .then(async (result) => {
          if (result) {
            let clonedAudit = Audit.clone(this.state.audit!);

            await clonedAudit.set();

            Modals.open(Lang.getI18N("duplicated-successfully"));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleUpdateAudit(updatedAudit: Audit) {
      this.state.audit! = updatedAudit;
    },
    cancelAuditEdit(modalRef: any) {
      modalRef.close();
      getState().navigate("auditing");
    },
    closeModal(modalRef: any) {
      modalRef.close();
    },
    cancelTogglePublish(modalRef: any) {
      let refs = <any>this.$refs;
      this.state.audit!.published = false;
      refs.published.toggle(false);
      modalRef.close();
    },
  },
  mounted() {
    if (
      this.state.audit?.published ||
      this.state.audit?.publishing_date != null
    ) {
      let refs = <any>this.$refs;
      refs.warningModal.open();
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.state.changeSaved) {
      next();
      return;
    }
    if (
      this.state.creation ||
      !compareObjects(this.state.initialAudit, this.state.audit)
    ) {
      const confirmLeave = await Modals.openYesNo(
        Lang.getI18N("unsaved-changes"),
        "Secureat",
        Lang.getI18N("yes"),
        Lang.getI18N("no")
      );
      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    "state.audit.published": function (newState) {
      if (newState) {
        let refs = <any>this.$refs;
        refs.publishConfirmationModal.open();
      }
    },
  },
  components: { AuditingModuleTab },
});
