import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7f413c17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex grow" }
const _hoisted_2 = {
  class: "flex col",
  style: {"width":"400px"}
}
const _hoisted_3 = { class: "panel" }
const _hoisted_4 = { class: "panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_SeDropdownFilter = _resolveComponent("SeDropdownFilter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SeDropdown, {
        modelValue: _ctx.targetType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.targetType) = $event)),
        titleFunc: (x)=>_ctx.Lang.getI18N(x)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SeDropdownItem, {
            value: _ctx.TrainingTargetType.everyone
          }, null, 8, ["value"]),
          _createVNode(_component_SeDropdownItem, {
            value: _ctx.TrainingTargetType.store
          }, null, 8, ["value"]),
          _createVNode(_component_SeDropdownItem, {
            value: _ctx.TrainingTargetType.groups
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["modelValue", "titleFunc"]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SeDropdownFilter, {
          ref: "stores",
          selectAll: true,
          search: true,
          labelFunction: (store) => store.name,
          items: _ctx.stores
        }, null, 8, ["labelFunction", "items"])
      ], 512), [
        [_vShow, _ctx.targetType == _ctx.TrainingTargetType.store]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SeDropdownFilter, {
          ref: "tags",
          labelFunction: (tag) => tag.name,
          items: _ctx.tags
        }, null, 8, ["labelFunction", "items"])
      ], 512), [
        [_vShow, _ctx.targetType == _ctx.TrainingTargetType.groups]
      ])
    ])
  ]))
}