import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6e3dfeaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["width", "height", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    class: _normalizeClass({
      'se-button': true,
      bold: true,
      disabled: _ctx.disabled && !_ctx.disabledWithoutStyles,
    }),
    type: "button",
    ref: "button"
  }, [
    (_ctx.tooltip != null)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          ref: "tooltip",
          class: "tooltip"
        }, _toDisplayString(_ctx.tooltip), 513))
      : _createCommentVNode("", true),
    (_ctx.icon && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          width: _ctx.iconSize,
          height: _ctx.iconSize,
          src: _ctx.icon
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_HalfCircleSpinner, {
          key: 2,
          style: {"color":"white","width":"30px","height":"30px"}
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}