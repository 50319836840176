
import { collection, doc, DocumentReference } from "firebase/firestore";
import { defineComponent } from "vue";
import * as Lang from "@/i18n/lang";
import { Role } from "@/model/User";
import { getState } from "@/pinia/AppState";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import Employee from "@/model/Employees/Employee";
import SecureatIllustration from "@/model/utils/SecureatIllustration";
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";
import * as Firebase from "@/firebase/Firebase";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import SeLoadingIndicator from "../global/SeLoadingIndicator.vue";
import { getDatabaseManager } from "@/firebase/DatabaseManager";

export default defineComponent({
  expose: ["onTabOpen"],
  data() {
    return {
      Role: Role,
      Lang: Lang,
      loading: false,
    };
  },

  setup() {
    var state = getStoreDatabaseState();

    return {
      toggleRight: getState().customStoreId == null,
      role: getState().userData?.role,
      state: state,
    };
  },
  methods: {
    async onTabOpen() {
      this.loading = true;

      this.state.roles = await getDatabaseManager().getEmployeeRoles(
        this.state.store.database
      );

      this.state.employees = await getEmployeeManager().getEmployees(
        this.state.store.ref
      );
      this.loading = false;
    },
    addEmployee() {
      this.state.employees.push(
        new Employee(
          doc(collection(Firebase.firestore, "employees")),
          "Nouvel ",
          "employé",
          true,
          null,
          null,
          [],
          [this.state.store.ref],
          [this.state.store.database],
          null
        )
      );
    },
    async saveEmployees() {
      var refs = <any>this.$refs;
      refs.modifyBtn.toggleLoading(true);

      for (let employee of this.state.employees) {
        await employee.set();
      }

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
    async onEmployeeIllustrationChange(
      event: ProgressEvent,
      file: File,
      employee: Employee
    ) {
      var path = await Storage.uploadEmployeeIllustration(employee, file);

      employee.illustration_url = new SecureatIllustration(
        await Storage.getDownloadUrl(path)
      );

      await employee.set();
    },
  },
});
