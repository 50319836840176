
import NavPanel from "@/nav/NavPanel.vue";
import NavHeader from "@/nav/NavHeader.vue";
import ToggleSwitch from "@/components/global/ToggleSwitch.vue";
import SeInput from "@/components/global/SeInput.vue";
import SeButton from "@/components/global/SeButton.vue";

import * as LabelsManager from "@/firebase/LabelsManager";
import * as Snackbars from "@/utils/Snackbars";
import * as DatabaseManager from "@/firebase/DatabaseManager";
import { getElementManager } from "@/firebase/ElementManager";
import * as Storage from "@/firebase/Storage";

import SeInputFile from "@/components/global/SeInputFile.vue";
import SeFadeButton from "@/components/global/SeFadeButton.vue";
import LockedField from "@/components/global/LockedField.vue";
import SeModal from "@/components/global/SeModal.vue";
import { defineComponent, reactive, ref } from "vue";
import Element from "@/model/Elements/Element";

import { getState } from "@/pinia/NavigationState";
import StoreModuleTab from "@/components/databases/elements/StoreModuleTab.vue";
import ElementType from "@/model/ElementType";
import Database from "@/model/Database";
import { nextTick } from "vue";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import StoreElement from "@/model/Elements/StoreElement";
import Store from "@/model/Store";
import * as Lang from "@/i18n/lang";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Modals from "@/utils/Modals";
import * as ImageUtils from "@/utils/ImageUtils";
import { getStoreElementState } from "@/pinia/elements/StoreElementState";
import { compareObjects } from "@/utils/ObjectUtils";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import Label from "@/model/Labels/Label";
import StoreLabel from "@/model/Labels/StoreLabel";
import * as UploadManager from "@/model/Uploads/UploadManager";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import * as ObjectUtils from "@/utils/ObjectUtils";

export default defineComponent({
  data() {
    return {
      imageModified: false,
      labelType: "expiration",
      currentLabel: null,
      manager: getElementManager(),
      Lang: Lang,
      imageFile: null as File | null,
    };
  },
  async setup() {
    let state = getStoreElementState();

    await state.setup();

    return {
      state: state,
    };
  },
  async mounted() {
    if (!this.state.creation) {
      await this.displayElementPicture();
      await this.displayType();
    }
  },
  expose: ["apply"],
  methods: {
    openEditTaskTypeModal(type: ElementType) {
      var refs = <any>this.$refs;

      refs.typeName.setValue(type.name);
      refs.typeModal.open(type);
    },
    closeIllustrationModal() {
      var refs = <any>this.$refs;
      refs.illustrationModal.close();
    },
    openIllustrationModal() {
      var refs = <any>this.$refs;
      refs.stockImages.search(this.state.element?.getName());
      refs.illustrationModal.open();
    },
    onNameChange(value: any) {
      var refs = <any>this.$refs;
      refs.stockImages.search(value);
    },
    async clone() {
      this.apply();

      let el = this.state.element!!;

      let refs = <any>this.$refs;

      if (refs.cloneBtn.isLoading()) {
        return;
      }
      refs.cloneBtn.toggleLoading(true);

      let cloned = StoreElement.duplicate(el, false);

      cloned.name += " (Copy)";

      await this.manager.createStoreElement(this.state.store!, cloned, null);

      let labels = <StoreLabel[]>refs.moduleTab.labels;

      labels = labels.map((x) =>
        x.duplicate(this.state.element?.originValue?.labels_lock!)
      );

      await LabelsManager.setLabels(cloned, labels);

      let state = getState();
      state.goBack();
    },
    openTypeModalCreation() {
      let refs = <any>this.$refs;
      refs.typeName.setValue("");
      refs.typeModal.open();
    },
    pickIllustration() {
      DOMUtils.selectFileDialog(this.pickIllustrationFromFile);
    },
    async pickIllustrationFromFile(e: any) {
      let picture: any = this.$refs.picture;
      let file = e.target.files[0];
      const fileReader = new FileReader();

      var instance = this;
      fileReader.addEventListener("load", function (evt: any) {
        if (!file.type.includes("image")) {
          return;
        }

        picture.src = evt.target.result;
        instance.modifyIllustration(file);
      });

      fileReader.readAsDataURL(file);
    },

    async createOrEditStoreType() {
      let refs = <any>this.$refs;

      if (!refs.typeForm.verify()) {
        return;
      }

      let typeName = refs.typeName.getValue();

      if (refs.typeModal.context == null) {
        let newType = await ElementTypeManager.createStoreElementType(
          this.state.store!,
          typeName
        );
        this.state.types.push(newType);

        this.state.selectedType = newType;
      } else {
        let type = <ElementType>refs.typeModal.context;
        type.name = typeName;
        await ElementTypeManager.updateElementType(type);
      }
      refs.typeModal.close();
    },
    displayType() {
      var type = this.state.element!.getType();

      if (type == null) {
        this.state.selectedType = null;

        return;
      }
      var results = this.state.types.filter((x) => x.ref?.id == type!.id);
      if (results.length > 0) {
        this.state.selectedType = results[0];
      }
    },
    async displayElementPicture() {
      let picture = <any>this.$refs.picture;
      picture.src = this.state.element!.getIcon();
    },
    async pickStockImageIllustration(url: string) {
      var refs = <any>this.$refs;

      let picture: any = this.$refs.picture;

      var buffer = await ImageUtils.loadImageAsArrayBuffer(url);

      var type = "image/png";

      const blob = new Blob([buffer], { type });

      ObjectUtils.blobToBase64(blob)
        .then((base64) => {
          picture.src = base64;
        })
        .catch((error) => {
          console.error("Error converting blob to Base64:", error);
        });

      const file = new File([blob], "Illustration", { type });

      this.state.element.illustration_url = url;

      this.modifyIllustration(file);

      refs.illustrationModal.close();
    },
    async modifyIllustration(file: any) {
      var refs = <any>this.$refs;

      let path =
        "stores/" +
        this.state.store!.ref.id +
        "/element_pictures/" +
        this.state.element?.ref.id!;

      var strategy = await UploadManager.createStrategy(file, path, false);

      this.state.element!.illustration_path = path;
      this.imageModified = true;
      UploadManager.addFileToUpload(strategy);

      refs.illustrationModal.close();
    },
    async apply() {
      let refs = <any>this.$refs;

      if (
        this.state.element!.getMinimumTemperature() != null &&
        this.state.element!.getMaximumTemperature() != null &&
        this.state.element!.getMinimumTemperature()! >
          this.state.element!.getMaximumTemperature()!
      ) {
        refs.message.innerHTML = Lang.getI18N("invalid-temperature-input");
        refs.modal.open();
        return;
      }

      if (this.state.element!.getName() == "") {
        refs.message.innerHTML = "Veuillez specifier un nom valide.";
        refs.modal.open();
        return;
      }

      if (refs.applyBtn.isLoading()) {
        return;
      }

      if (
        this.state.selectedType == null &&
        this.state.element!.getType() == null
      ) {
        Modals.open("Veuillez selectionner un type d'élement.");
        return;
      }

      refs.applyBtn.toggleLoading(true);

      if (this.state.selectedType != null) {
        this.state.element!.type = this.state.selectedType.ref;
      } else {
        this.state.element!.type = null;
      }

      if (this.state.selectedProbe != null) {
        this.state.element!.probe_id = this.state.selectedProbe?.probeUID;
      } else {
        this.state.element!.probe_id = null;
      }

      if (this.state.selectedTag != null) {
        this.state.element.probe_id = this.state.selectedTag.id;
      }

      try {
        await UploadManager.applyUploads();

        if (this.state.element?.attachments != null) {
          for (let attachment of this.state.element?.attachments!) {
            attachment.generateUrl();
          }
        }

        if (this.imageModified) {
          this.state.element!.illustration_url = await Storage.getDownloadUrl(
            this.state.element?.illustration_path!
          );
        }

        if (this.state.creation) {
          this.state.creation = false;
        }

        await this.state.element?.set();

        await LabelsManager.setLabels(
          this.state.element!,
          refs.moduleTab.labels
        );

        await UploadManager.applyDeletions();
        this.state.changeSaved = true;
        refs.applyBtn.toggleLoading(false);
        Snackbars.display(Lang.getI18N("saved-successfully"));
      } catch (e: any) {
        Snackbars.display(Lang.getI18N("save-error"), 3000, "error");
        refs.applyBtn.toggleLoading(false);
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.state.changeSaved) {
      next();
      return;
    }
    if (
      this.state.creation ||
      !compareObjects(this.state.initialElement, this.state.element)
    ) {
      const confirmLeave = await Modals.openYesNo(
        Lang.getI18N("unsaved-changes"),
        "Secureat",
        Lang.getI18N("yes"),
        Lang.getI18N("no")
      );
      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    SeInputFile,
    SeButton,
    SeFadeButton,
    LockedField,
    SeModal,
    StoreModuleTab,
  },
});
