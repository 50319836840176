
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Info",
      required: true,
    },
    noHeader: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  expose: ["open", "close", "context", "onClose"],
  data() {
    return {
      context: null,
      displayed: false,
    };
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onKeyDown(event: KeyboardEvent) {
      const body = this.$refs.body as HTMLElement;
      if (this.isOpen()) {
        if (event.key === "Escape") {
          this.close();
        }
        if (event.key === "Enter") {
          if (
            document.activeElement.tagName === "INPUT" ||
            document.activeElement.tagName === "TEXTAREA"
          ) {
            return;
          }
          const element = body.querySelector(".modal-valid") as HTMLElement;
          if (element) {
            element.click();
          }
        }
      }
    },
    isOpen() {
      return this.displayed;
    },
    close() {
      const modal = this.$refs.modal as HTMLElement;
      modal.classList.remove("anim");
      modal.querySelector(".modal-content")?.classList.remove("anim-content");
      modal.style.display = "none";
      this.$emit("onClose");
      this.displayed = false;
    },
    open(context: any = null) {
      this.context = context;
      const modal = this.$refs.modal as HTMLElement;
      modal.style.display = "flex";
      modal.style.visibility = "visible";
      modal.classList.add("anim");
      modal.querySelector(".modal-content")?.classList.add("anim-content");
      this.displayed = true;

      return this.$refs.modal;
    },
  },
});
