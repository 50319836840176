
import { getHomeState } from "@/pinia/home/HomeState";
import { defineComponent } from "vue";
import * as DateUtils from "@/utils/DateUtils";
import SeProgressBar from "../global/SeProgressBar.vue";
import { getCommunicationStatsState } from "@/pinia/communications/CommunicationStatsState";
import { getState } from "@/pinia/NavigationState";
import Communication from "@/model/Communication/Communication";
import Employee from "@/model/Employees/Employee";
import CommunicationResult from "@/model/Communication/CommunicationResult";
import Training from "@/model/Trainings/Training";
import { getTrainingStatsState } from "@/pinia/trainings/TrainingStatsState";
import TrainingResult from "@/model/Trainings/TrainingResult";

export default defineComponent({
  name: "CommunicationAndTraining",
  data() {
    return {
      DateUtils: DateUtils,
    };
  },
  setup() {
    return {
      homeState: getHomeState(),
    };
  },
  methods: {
    getTrainingPercentageView(training: Training) {
      let activeEmployees = (this.homeState.employees as Employee[]).filter(
        (x) => x.active
      );

      let count = 0;

      let length = activeEmployees.length;

      var results = this.homeState.trainingResults.get(
        training
      ) as TrainingResult[];

      if (!results) {
        return 0;
      }

      for (let result of results) {
        if (activeEmployees.find((x) => x.ref.id == result.employee.id)) {
          count++;
        }
      }

      if (count == 0) {
        return 0;
      }
      return Math.round((count / length) * 100);
    },
    getCommunicationPercentageView(communication: Communication) {
      let activeEmployees = (this.homeState.employees as Employee[]).filter(
        (x) => x.active
      );

      let count = 0;

      let length = activeEmployees.length;

      var results = this.homeState.communicationResults.get(
        communication
      ) as CommunicationResult[];

      if (!results) {
        return 0;
      }

      for (let result of results) {
        if (activeEmployees.find((x) => x.ref.id == result.employee.id)) {
          count++;
        }
      }

      if (count == 0) {
        return 0;
      }
      return Math.round((count / length) * 100);
    },
    openCommunicationStats(communication: Communication) {
      getCommunicationStatsState().set(communication);
      getState().navigate("communicationStats", false);
    },
    openTrainingStats(training: Training) {
      getTrainingStatsState().set(training);
      getState().navigate("trainingStats", false);
    },
  },
});
