
import { defineComponent } from "vue";
import * as ColorsUtils from "@/utils/ColorUtils";

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: "green", // Default color variant
    },
  },

  data() {
    let colors: Map<string, string> = new Map();

    colors.set("red", "#e63946");
    colors.set("blue", "#4361ee");
    colors.set("yellow", "#f4a261");
    colors.set("orange", "#fa8c1e");
    colors.set("green", "#17b26a");

    return {
      colors: colors,
    };
  },

  computed: {
    innerStyle() {
      return {
        background: this.colors.get(this.variant),
      };
    },
    outerStyle() {
      var color = ColorsUtils.changeHexOpacity(
        this.colors.get(this.variant),
        0.2
      );
      return {
        background: color,
      };
    },
  },
});
