import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "text-muted",
  style: {"font-weight":"bold"}
}
const _hoisted_2 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeLoadingIndicator = _resolveComponent("SeLoadingIndicator")!
  const _component_SeImage = _resolveComponent("SeImage")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createVNode(_component_SeImage, {
        maxWidth: "40",
        maxHeight: "40",
        src: _ctx.result.element.getIcon()
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SeLoadingIndicator, { size: 40 })
        ]),
        _: 1
      }, 8, ["src"])
    ]),
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.result.element.getName()), 1),
    _createElementVNode("td", _hoisted_2, [
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayPictures())),
        class: "image-button",
        src: "/ico/eye-blue.svg"
      })
    ])
  ]))
}