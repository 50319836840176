
import NavHeader from "@/nav/NavHeader.vue";
import BulkDatabaseTaskTable from "@/components/bulk/BulkDatabaseTaskTable.vue";

import { defineComponent } from "vue";
import { getBulkDatabaseTaskState } from "@/pinia/tasks/BulkDatabaseTaskState";
import * as Lang from "@/i18n/lang";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  async setup() {
    let state = getBulkDatabaseTaskState();

    await state.setup();

    return {
      state: state,
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  async mounted() {},
  methods: {},
  components: { BulkDatabaseTaskTable },

  async beforeRouteLeave(to, from, next) {
    if (to.name == "taskConf") {
      next();
      return;
    }
    let changes = getBulkDatabaseTaskState().hasChanges();

    if (!changes) {
      next();
    } else {
      const confirmLeave = await Modals.openYesNo(
        Lang.getI18N("unsaved-changes"),
        "Secureat",
        Lang.getI18N("yes"),
        Lang.getI18N("no")
      );

      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    }
  },
});
