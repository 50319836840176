import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-68ecdfda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex col" }
const _hoisted_2 = {
  class: "flex wrap",
  style: {"gap":"24px"}
}
const _hoisted_3 = {
  class: "shop-item",
  style: {"width":"350px","height":"525px","background-color":"white","border-radius":"12px","display":"flex","align-items":"center","justify-content":"center"}
}
const _hoisted_4 = { class: "flex grow align-center justify-center" }
const _hoisted_5 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_DistributorShopItemCard = _resolveComponent("DistributorShopItemCard")!
  const _component_SeImage = _resolveComponent("SeImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SeInput, {
      type: "email",
      placeholder: _ctx.Lang.getI18N('shop.email'),
      modelValue: _ctx.state.distributor.shop_email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.distributor.shop_email) = $event)),
      placeholderBase: "exemple@mail.com"
    }, null, 8, ["placeholder", "modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.shopItems, (item) => {
        return (_openBlock(), _createElementBlock("div", null, [
          (item.active || _ctx.state.displayUnactives)
            ? (_openBlock(), _createBlock(_component_DistributorShopItemCard, {
                key: 0,
                shopItem: item,
                onMoveRight: () => _ctx.moveItemRight(item),
                onMoveLeft: () => _ctx.moveItemLeft(item)
              }, null, 8, ["shopItem", "onMoveRight", "onMoveLeft"]))
            : _createCommentVNode("", true)
        ]))
      }), 256)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addShopItem && _ctx.addShopItem(...args))),
            class: "flex add-btn"
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.Lang.getI18N("new")), 1),
            _createVNode(_component_SeImage, { src: "/ico/plus-blue.svg" })
          ])
        ])
      ])
    ])
  ]))
}