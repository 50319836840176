export class CSVUtils {
  static downloadCSV(data: string, filename: string): void {
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");

    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename; // Set the filename for the download

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
  static convertToCSV(
    data: Array<any>,
    delimiter: string = ",",
    hasHeader: boolean = true
  ): string {
    if (data.length === 0) {
      return "";
    }
    let headers: string[] = [];
    let csvRows: string[] = [];
    if (hasHeader && typeof data[0] === "object" && !Array.isArray(data[0])) {
      headers = Object.keys(data[0]);
      csvRows.push(headers.join(delimiter));
      data.forEach((record: any) => {
        const row = headers
          .map((header) => (record[header] !== undefined ? record[header] : ""))
          .join(delimiter);
        csvRows.push(row);
      });
    } else if (Array.isArray(data[0])) {
      data.forEach((record) => {
        csvRows.push(record.join(delimiter));
      });
    } else {
      headers = Object.keys(data[0]);
      csvRows.push(headers.join(delimiter));
      data.forEach((record) => {
        const row = headers.map((header) => record[header]).join(delimiter);
        csvRows.push(row);
      });
    }
    return csvRows.join("\n");
  }

  static writeCSVFile(
    data: Array<any>,
    delimiter: string = ",",
    hasHeader: boolean = true
  ): string {
    const csvContent = CSVUtils.convertToCSV(data, delimiter, hasHeader);
    return csvContent;
  }
}
