import { defineStore } from "pinia";
import Database from "@/model/Database";
import Distributor from "@/model/Distributor";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import { getElementManager } from "@/firebase/ElementManager";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { getDistributorManager } from "@/firebase/DistributorManager";
import EmployeeRole from "@/model/Employees/EmployeeRole";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import Tag from "@/model/Tag";
import DatabaseShopitem from "@/model/Shop/DatabaseShopItem";
import { getShopManager } from "@/firebase/ShopManager";
import { getState } from "../AppState";
import { Role } from "@/model/User";
import { getStoreManager } from "@/firebase/StoreManager";

interface DatabaseState {
  initialDatabase: Database | null;
  database: Database | null;
  tabIndex: number | null;
  selectedDistributor: Distributor | null;
  elementSearch: string;
  taskSearch: string;
  scrollY: number;
  displayUnactives: boolean;
  taskConfigurations: DatabaseTaskConfiguration[];
  elements: DatabaseElement[];
  distributors: Distributor[];
  employee_roles: EmployeeRole[];
  tags: Tag[];
  shopItems: DatabaseShopitem[];
}

export const getDatabaseState = defineStore("databaseState", {
  state: (): DatabaseState => {
    return {
      selectedDistributor: null,
      database: null,
      initialDatabase: null,
      tabIndex: 0,
      elementSearch: "",
      taskSearch: "",
      scrollY: 0,
      displayUnactives: false,
      taskConfigurations: [],
      elements: [],
      distributors: [],
      employee_roles: [],
      tags: [],
      shopItems: [],
    };
  },
  actions: {
    async setup() {
      let taskConfigurationManager = getTaskConfigurationManager();

      await taskConfigurationManager.ensureDatabaseTaskConfigurationListener(
        this.database?.ref?.id!
      );

      taskConfigurationManager.getDatabaseTaskListener(
        this.database?.ref?.id!
      ).updated = (taskConfigurations: DatabaseTaskConfiguration[]) => {
        this.taskConfigurations = taskConfigurations;
      };

      this.taskConfigurations =
        taskConfigurationManager.getDatabaseTaskConfigurations(
          this.database!.ref!.id
        );

      if (this.database!.distributor == null) {
        await getDistributorManager().initialize();

        this.distributors = getDistributorManager().getDistributors();
      }

      let elementManager = getElementManager();

      await elementManager.ensureDatabaseElementListener(
        this.database?.ref?.id!
      );

      elementManager.getDatabaseElementListener(
        this.database?.ref?.id!
      ).updated = (elements: DatabaseElement[]) => {
        this.elements = elements;
      };

      this.elements = elementManager.getDatabaseElements(
        this.database?.ref?.id!
      );

      this.tags = await getDatabaseManager().getStoreTags(this.database!);

      this.employee_roles = await getDatabaseManager().getEmployeeRoles(
        this.database!.ref
      );

      await getStoreManager().initialize();
    },
    set(database: Database) {
      this.initialDatabase = database;
      this.database = Database.clone(database);
    },
  },
});
