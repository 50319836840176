
import * as StoreManager from "../../firebase/StoreManager";
import { getStoreState } from "@/pinia/stores/StoreState";
import { useRouter } from "vue-router";
import { getState } from "@/pinia/NavigationState";
import { defineComponent, nextTick } from "vue";
import Store from "@/model/Store";
import ToggleSwitch from "../global/ToggleSwitch.vue";
import { getDistributorState } from "@/pinia/distributors/DistributorState";
import DistributorShopItem from "@/model/Shop/DistributorShopItem";
import * as Firebase from "@/firebase/Firebase";
import { collection, doc } from "firebase/firestore";
import * as DOMUtils from "@/utils/DOMUtils";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as ImageUtils from "@/utils/ImageUtils";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getShopState } from "@/pinia/shop/ShopState";
import DatabaseShopitem from "@/model/Shop/DatabaseShopItem";
import Database from "@/model/Database";
import * as Snackbars from "@/utils/Snackbars";
import * as Lang from "@/i18n/lang";
import DatabaseShopItem from "@/model/Shop/DatabaseShopItem";

export default defineComponent({
  props: {
    shopItem: {
      type: DistributorShopItem,
      required: true,
    },
    onMoveRight: {
      type: Function,
      required: false,
      default: null,
    },
    onMoveLeft: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      hasIllustration: this.shopItem.illustration != null,
      state: getShopState(),
      databaseShopItems: new Map() as Map<Database, DatabaseShopItem>,
      Lang: Lang,
    };
  },
  setup() {},
  mounted() {},
  methods: {
    async savePrices() {
      var refs = <any>this.$refs;

      refs.savePriceBtn.toggleLoading(true);
      for (let item of this.databaseShopItems.values()) {
        await item.set();
      }
      refs.savePriceBtn.toggleLoading(false);
      refs.pricesModal.close();

      Snackbars.display(Lang.getI18N("price-updated"));
    },
    async openPrices() {
      await this.savePrices();

      var refs = <any>this.$refs;

      refs.openPriceBtn.toggleLoading(true);

      await this.state.initDatabaseShops();

      for (let database of this.state.databaseShopItems.keys()) {
        let item = this.state.databaseShopItems
          .get(database)
          .find(
            (x: DatabaseShopItem) =>
              x.origin != null && x.origin.id == this.shopItem.ref.id
          );

        if (item != null) {
          this.databaseShopItems.set(database, item);
        }
      }

      refs.pricesModal.open();

      refs.openPriceBtn.toggleLoading(false);
    },
    pickIllustration(shopItem: DistributorShopItem) {
      DOMUtils.selectFileDialog(
        (e: any) => this.onFileSelect(e, shopItem),
        " image/*"
      );
    },
    onFileSelect(e: any, shopItem: DistributorShopItem) {
      let picture: any = this.$refs.picture;
      let file = e.target.files[0];
      const fileReader = new FileReader();

      var refs = <any>this.$refs;
      var instance = this;
      fileReader.addEventListener("load", async function (evt: any) {
        instance.hasIllustration = true;

        refs.illustration.src = evt.target.result;

        let path =
          "distributors/" +
          getDistributorState().distributor?.ref.id +
          "/shops/";

        shopItem.illustration_path = path + file.name;

        var strategy = await UploadManager.createStrategy(file, path, true);

        UploadManager.addFileToUpload(strategy);
      });

      fileReader.readAsDataURL(file);
    },
  },
});
