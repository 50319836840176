
import User, { Role } from "@/model/User";
import * as Lang from "@/i18n/lang";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import { defineComponent, nextTick } from "vue";
import * as Storage from "@/firebase/Storage";
import SecureatIllustration from "@/model/utils/SecureatIllustration";
import * as Snackbars from "@/utils/Snackbars";
import * as ModuleManager from "@/firebase/ModuleManager";
import { ModuleEnum } from "@/model/ModuleEnum";
import { ExtensionEnum } from "@/model/ExtensionEnum";
import { getState } from "@/pinia/AppState";
import { getAllergensState } from "@/pinia/allergens/AllergensState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getStoreManager } from "@/firebase/StoreManager";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import * as MailManager from "@/firebase/MailManager";
import { getUserManager } from "@/firebase/UserManager";
import * as Modals from "@/utils/Modals";
import SeLoadingIndicator from "../global/SeLoadingIndicator.vue";
import ReadonlyField from "../global/ReadonlyField.vue";

export default defineComponent({
  expose: ["onTabOpen"],
  data() {
    return {
      Role: Role,
      Lang: Lang,
      loading: false,
    };
  },

  setup() {
    var state = getStoreDatabaseState();

    return {
      tablet: getState().initialPage != null,
      toggleRight: getState().customStoreId == null,
      role: getState().userData?.role,
      state: state,
    };
  },
  methods: {
    async onTabOpen() {
      this.loading = true;

      this.displayHiddenModules();
      this.displayHiddenExtensions();

      this.state.owners = await getUserManager().getStoreOwners(
        this.state.store!
      );

      this.loading = false;
    },
    displayHiddenExtensions() {
      var refs = <any>this.$refs;

      var results = [];

      var values = Object.values(ExtensionEnum);

      let i = 0;

      for (let val of values) {
        if (!this.state.store!.hidden_extensions.includes(val)) {
          results.push(i);
        }
        i++;
      }

      refs.extensionsSelect.setSelectedIndexes(results);
    },

    displayHiddenModules() {
      var refs = <any>this.$refs;

      let indexes: number[] = [];

      let availableModules = this.getModuleEnums();

      availableModules.forEach((availableModule, index) => {
        if (
          ModuleManager.isModuleActivated(this.state.store!, availableModule)
        ) {
          indexes.push(index);
        }
        refs.modulesSelect.setSelectedIndexes(indexes);
      });
    },
    async addOwner() {
      let refs = <any>this.$refs;

      let inputEmail = refs.ownerName.getValue();

      if (inputEmail.trim() == "") {
        return;
      }

      var result = await SecureatServerApi.addStoreOwner(
        inputEmail,
        this.state.store!.ref!.id
      );

      if (result.status == "not-elligible") {
        Snackbars.display(
          "Cet utilisateur n'est pas elligible a l'établissement."
        );

        return;
      }

      if (result.status == "invited") {
        const mailRecipient = [inputEmail];
        const mailSubject = Lang.getI18N("email.invitation.subject");
        const baseUrl = window.location.origin;

        const mailLink = `<a href='${baseUrl}?email=${inputEmail}' target='_blank'>se-dashboard.web.app</a>`;

        const mailBodyRegister = `${Lang.getI18N("email.invitation.body")} "${
          this.state.store?.name
        }", ${Lang.getI18N("email.invitation.body.register")}: ${mailLink}`;

        MailManager.sendEmail(mailRecipient, mailSubject, mailBodyRegister);
        Snackbars.display(
          `${Lang.getI18N("email.invitation.snackbar.sent")} ${mailRecipient}`
        );
      } else {
      }

      if (result.status == "ok") {
        this.state.owners = await getUserManager().getStoreOwners(
          this.state.store!
        );

        refs.ownersModal.$forceUpdate();

        Snackbars.display("L'utilisateur a été ajouté en propriétaire.");
      }
    },
    async removeOwner(user: User) {
      user.stores = user.stores.filter(
        (obj) => obj.id !== this.state.store!.ref?.id
      );

      let storeManager = getStoreManager();

      let stores = await storeManager.getStoresOfUser(user);

      for (let distributor of user.store_distributors) {
        if (!stores.some((x) => x.distributor.id == distributor.id)) {
          user.store_distributors.filter((x) => x == distributor);
        }
      }

      this.state.owners = this.state.owners!.filter((x) => x != user);
      await user.set();
    },

    getLabelFromI18N(module: String) {
      return Lang.getI18N(module.toString());
    },
    getModuleEnums() {
      let results = [];

      let modules = ModuleManager.getModuleEnums();

      for (let module of modules) {
        if (
          ModuleManager.isModuleActivatedDatabase(this.state.database!, module)
        ) {
          results.push(module);
        }
      }

      return results;
    },
    getExtensionEnums() {
      this.state.database.hidden_extensions;
      // take in account database unactive
      var values = Object.values(ExtensionEnum);

      var results = [];

      for (let value of values) {
        if (!this.state.database!.hidden_extensions.includes(value)) {
          results.push(value);
        }
      }
      return results;
    },

    openOwnersModal() {
      let refs = <any>this.$refs;
      refs.ownersModal.open();
    },
    openAllergensView() {
      if (this.state.database) {
        getAllergensState().set(
          this.state.elements,
          this.state.store!,
          this.state.database
        );
        getNavigationState().navigate("allergens");
      } else {
        getAllergensState().set(this.state.elements, this.state.store!, null);
        getNavigationState().navigate("allergens");
      }
    },
    openModulesModal() {
      var refs = <any>this.$refs;
      refs.moduleModal.open();
    },
    async save() {
      var refs = <any>this.$refs;

      if (refs.modifyBtn.isLoading()) {
        return;
      }

      refs.modifyBtn.toggleLoading(true);
      this.state.store!.hidden_modules = [];

      let selectedIndexes = refs.modulesSelect.getSelectedIndexes();
      let selectedModules: string[] = [];
      let availableModules = this.getModuleEnums();

      availableModules.forEach((availableModule, index) => {
        if (
          ModuleManager.isModuleActivated(this.state.store!, availableModule)
        ) {
          if (selectedIndexes.includes(index)) {
            selectedModules.push(availableModule);
          }
        }
      });

      for (let idStr in Object.keys(ModuleEnum)) {
        let id = parseInt(idStr);
        let module = ModuleManager.getModuleFromIndex(id);

        if (
          !ModuleManager.isModuleActivatedDatabase(
            this.state.database!,
            module.toEnum()
          )
        ) {
          continue;
        }

        if (!selectedModules.includes(module.key)) {
          this.state.store!.hidden_modules.push(module.ref!);
        }
      }

      this.state.store!.hidden_extensions = [];

      let extensionIds = refs.extensionsSelect.getSelectedIndexes();

      let i = 0;
      for (let value of Object.values(ExtensionEnum)) {
        if (!extensionIds.includes(i)) {
          this.state.store!.hidden_extensions.push(value);
        }
        i++;
      }

      console.log(this.state.store!.hidden_extensions);

      await this.state.store?.set();

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
    async onIllustrationChange(event: ProgressEvent, file: File) {
      await Storage.uploadStoreIllustration(this.state.store!, file);

      this.state.store!.illustration_url = new SecureatIllustration(
        await Storage.getStoreIllustrationUrl(this.state.store!)
      );

      await this.state.store!.set();
    },
  },
  watch: {
    "state.store.secureat_licence"(newValue, oldValue) {
      if (newValue === false && oldValue) {
        Modals.open(
          "Attention : Désactiver la licence Secureat entrainera une interruption immediate des services de la tablette."
        );
      }
    },
  },
});
