
import StoreElement from "@/model/Elements/StoreElement";
import { ModuleEnum } from "@/model/ModuleEnum";
import Task from "@/model/Task";
import { getHomeState } from "@/pinia/home/HomeState";
import { defineComponent } from "vue";
import * as TaskManager from "@/firebase/TaskManager";
import { EquipementTemperaturesFilter } from "./EquipementTemperaturesFilter";
import { ElementTemperatureStateEnum } from "./ElementTemperatureStateEnum";

export default defineComponent({
  methods: {
    onFilterChange(index: number) {
      switch (index) {
        case 0:
          this.filter = EquipementTemperaturesFilter.Fridges;
          break;
        case 1:
          this.filter = EquipementTemperaturesFilter.Deliveries;
          break;
        case 2:
          this.filter = EquipementTemperaturesFilter.Oils;
          break;
      }
    },
  },

  data() {
    let filter: EquipementTemperaturesFilter =
      EquipementTemperaturesFilter.Fridges;

    return {
      filter: filter,
      ElementTemperatureStateEnum: ElementTemperatureStateEnum,
      EquipementTemperaturesFilter: EquipementTemperaturesFilter,
    };
  },
  setup() {
    {
      return {
        homeState: getHomeState(),
      };
    }
  },
});
