
import NavPanel from "@/nav/NavPanel.vue";
import NavHeader from "@/nav/NavHeader.vue";
import BulkElementTable from "@/components/bulk/BulkElementTable.vue";

import { defineComponent } from "vue";
import { getBulkElementState } from "@/pinia/elements/BulkDatabaseElementState";
import * as Lang from "@/i18n/lang";
import BulkDatabaseElementTable from "@/components/bulk/BulkDatabaseElementTable.vue";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  async setup() {
    let state = getBulkElementState();

    await state.setup();

    return {
      state: state,
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  async mounted() {},

  async beforeRouteLeave(to, from, next) {
    if (to.name == "databaseElement") {
      next();
      return;
    }
    let changes = getBulkElementState().hasChanges();

    if (!changes) {
      next();
    } else {
      const confirmLeave = await Modals.openYesNo(
        Lang.getI18N("unsaved-changes"),
        "Secureat",
        Lang.getI18N("yes"),
        Lang.getI18N("no")
      );
      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    }
  },

  methods: {},
  components: { BulkDatabaseElementTable },
});
