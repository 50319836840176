import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
  FieldPath,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Element from "../model/Elements/Element";
import Database from "../model/Database";
import User, { Role } from "../model/User";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import * as Firebase from "@/firebase/Firebase";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getStoreManager } from "@/firebase/StoreManager";
import Tag from "@/model/Tag";
import Communication from "@/model/Communication/Communication";
import { getDatabaseManager } from "./DatabaseManager";
import CommunicationResult from "@/model/Communication/CommunicationResult";
import Store from "@/model/Store";

interface CommunicationManager {}

export const getCommunicationManager = defineStore("CommunicationManager", {
  state: (): CommunicationManager => {
    return {};
  },
  actions: {
    async getActiveCommunications(store: Store): Promise<Communication[]> {
      if (store.database === null) {
        return;
      }

      var results = [] as Communication[];
      var communications: Communication[] = await this.getCommunications(
        store.database
      );

      for (let communication of communications) {
        if (
          communication.target.stores.length == 0 &&
          communication.target.store_tags.length == 0
        ) {
          results.push(communication);
          continue;
        }

        if (
          communication.target.stores.length > 0 &&
          communication.target.store_tags.length == 0 &&
          !communication.target.stores.includes(store.ref)
        ) {
          continue;
        }

        if (communication.target.store_tags_union) {
          if (
            store.tags.some((x) => communication.target.store_tags.includes(x))
          ) {
            results.push(communication);
            continue;
          }
        } else {
          if (
            store.tags.some((x) => !communication.target.store_tags.includes(x))
          ) {
            continue;
          }

          results.push(communication);
        }
      }

      return results.filter(
        (x) => x.start <= new Date() && x.end >= new Date()
      );
    },
    async getCommunications(
      databaseRef: DocumentReference
    ): Promise<Communication[]> {
      const q = query(
        collection(Firebase.firestore, databaseRef.path + "/communications")
      );

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => Communication.fromFirestore(x));
    },

    async fetchCommunicationResults(
      communication: Communication
    ): Promise<CommunicationResult[]> {
      let path = communication.ref.path + "/results";

      const q = query(
        collection(Firebase.firestore, path),
        where("completed_timestamp", "!=", null)
      );

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => CommunicationResult.fromFirestore(x));
    },
    async getCommunicationResults(communication: Communication) {
      let path = communication.ref.path + "/results";

      const q = query(
        collection(Firebase.firestore, path),
        where("completed_timestamp", "!=", null)
      );

      return new SnapshotListener<CommunicationResult>(
        CommunicationResult.fromFirestore,
        q
      );
    },
  },
});
