import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6fbe19a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "flex justify-between center" }
const _hoisted_3 = {
  class: "flex",
  style: {"margin":"24px"}
}
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { ref: "temperaturePanel" }
const _hoisted_6 = { class: "tables-list" }
const _hoisted_7 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_8 = {
  key: 0,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SeDropdownFilter = _resolveComponent("SeDropdownFilter")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_AuditResultList = _resolveComponent("AuditResultList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      backButton: "true",
      title: _ctx.state.audit?.name
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SeDropdownFilter, {
        search: true,
        style: {"width":"500px","margin":"24px"},
        title: "Results",
        ref: "resultsSelect",
        labelFunction: (result) => _ctx.getResultLabel(result),
        items: _ctx.state.results,
        onSelectedIndexesChange: _ctx.setSelectedResults
      }, null, 8, ["labelFunction", "items", "onSelectedIndexesChange"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SeButton, {
          ref: "openPDF",
          onClick: _ctx.openPDF,
          disabled: !_ctx.isOneResultSelected || _ctx.isLoading,
          title: 
            !_ctx.isOneResultSelected
              ? _ctx.Lang.getI18N('audit.open-pdf-info')
              : _ctx.Lang.getI18N('open-pdf')
          
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("open-pdf")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled", "title"]),
        _createVNode(_component_SeButton, {
          ref: "openCSV",
          onClick: _ctx.openCSV,
          disabled: !_ctx.isOneOrMoreResultSelected,
          title: 
            !_ctx.isOneResultSelected
              ? _ctx.Lang.getI18N('audit.open-csv-info')
              : _ctx.Lang.getI18N('open-csv')
          
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("open-csv")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled", "title"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.state.results.length <= 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.Lang.getI18N("audit-no-results")), 1))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.state.selectedResults && _ctx.state.selectedResults.length <= 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.Lang.getI18N("select-results")), 1))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.state.audit?.getSections(), (section) => {
                      return (_openBlock(), _createElementBlock("div", null, [
                        _createVNode(_component_AuditResultList, { section: section }, null, 8, ["section"])
                      ]))
                    }), 256))
              ], 64))
        ])
      ], 512)
    ])
  ]))
}